import { Row, Col, Typography, Button, Flex, Space } from "antd";
import { Colors } from "constants/colors";
import {
  ButtonApprove,
  ButtonTransparent,
  DropDownCardMenu,
  handleCopyLink,
  openNotification,
} from "shared/components";
import { ProviderFlow } from "./Flow";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getData } from "store/appliancesSlice";
import useWindowWidth from "shared/hooks/useWindowWidth ";
import { SmallScreen } from "./SmallScreen";
import { ArrowDown, ArrowRite, CopyLink, Planet } from "./icons";
import { useParams } from "react-router-dom";
import {
  getProject,
  selectProject,
  updateProject,
  updateSaved,
  updateSimulation,
} from "store/projectSlice";
import { ROLES, TypeDropDown } from "shared/interfaces";
import {
  selectSequences,
  getData as getDataSequence,
  playSequences,
} from "store/sequencesSlice";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { useTranslation } from "react-i18next";
import { selectUser } from "store/userSlice";
import { ReactFlowProvider } from "@xyflow/react";
import { DnDProvider } from "./DndProvider";
const { Title, Text } = Typography;

export const Project = () => {
  const dispatch = useAppDispatch();
  const windowWidth = useWindowWidth();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getData());
    if (id) {
      dispatch(getProject(id));
      dispatch(getDataSequence(id));
    }
  }, []);

  if (windowWidth < 896) {
    return <SmallScreen />;
  }

  return (
    <>
      <HeadProject />
      <ReactFlowProvider>
        <DnDProvider>
          <ProviderFlow />
        </DnDProvider>
      </ReactFlowProvider>
    </>
  );
};

const HeadProject = () => {
  const dispatch = useAppDispatch();
  const { project, saved, temporaryProject } = useAppSelector(selectProject);
  const { data } = useAppSelector(selectSequences);
  const [simulation, setSimulation] = useState(false);
  const { name, state_template, id, is_favorite, publish } = project || {};
  const { t } = useTranslation();
  const { roles } = useAppSelector(selectUser);
  const isUser = roles.includes(ROLES.USER);

  const handleSimulation = () => {
    setSimulation(!simulation);
    dispatch(updateSimulation());
    dispatch(
      playSequences(
        simulation
          ? []
          : data?.length && data[0]?.parameters?.length
          ? data[0].parameters
          : []
      )
    );
  };
  const handlePublish = async () => {
    await axios
      .put(ENDPOINTS.projectById(project?.id), {
        publish: publish === 1 ? 0 : 1,
      })
      .then(() => {
        openNotification("success");
        setTimeout(() => dispatch(getProject(id)), 1000);
      })
      .catch((e) => {
        openNotification("error");
      });
  };

  const handleSaved = useCallback(() => {
    dispatch(updateProject(removeChecked(temporaryProject)));
    dispatch(updateSaved(true));
  }, [temporaryProject]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSaved();
    }, 30000); // 30 seconds

    return () => clearInterval(intervalId);
  }, [handleSaved]);

  const titleNaming =
    state_template && is_favorite
      ? t("Template (Favourited)")
      : is_favorite
      ? t("My project (Favourited)")
      : state_template
      ? t("Template")
      : t("My project");

  return (
    <>
      <Row
        justify={"space-between"}
        align="middle"
        style={{
          padding: "16px 32px",
          background: "#fff",
          borderBottom: "1px solid #D2D2D7",
        }}
      >
        <Flex align="center">
          <Title
            level={5}
            style={{ color: Colors.MainDark300, margin: 0, fontWeight: 400 }}
          >
            {titleNaming} &nbsp;/
          </Title>
          <Col style={{ marginLeft: 8 }}>
            <DropDownCardMenu
              data={project}
              type={TypeDropDown.EDIT}
              reactFlowWrapper={null}
              customDrop={
                <a onClick={(e) => e.preventDefault()}>
                  <Flex align="center">
                    <Space
                      style={{
                        color: Colors.MainDark500,
                        fontSize: 16,
                        fontWeight: 700,
                        marginRight: 8,
                      }}
                    >
                      {name}
                    </Space>
                    <ArrowDown />
                  </Flex>
                </a>
              }
            />
          </Col>
        </Flex>
        <Flex align="center">
          <Text
            style={{
              color: Colors.MainDark300,
              fontSize: 16,
              marginRight: 32,
            }}
          >
            {saved ? t("Saved") : t("Unsaved changes")}
          </Text>

          {!isUser && !!state_template && (
            <Button
              type="link"
              style={{
                color: !!publish ? Colors.MainDark300 : Colors.MainBlue500,
                fontSize: 16,
                fontWeight: 700,
                padding: 0,
                marginRight: 16,
              }}
              onClick={handlePublish}
            >
              <Planet
                style={{
                  stroke: !!publish ? Colors.MainDark300 : Colors.MainBlue500,
                }}
              />
              {!!publish ? t("Unpublish") : t("Publish")}
            </Button>
          )}

          <Button
            type="link"
            style={{
              color: Colors.MainBlue500,
              fontSize: 16,
              fontWeight: 700,
              padding: 0,
            }}
            onClick={() => handleCopyLink(id)}
          >
            <CopyLink />
            {t("Copy link")}
          </Button>
          <ButtonApprove
            text={t("Save")}
            handle={handleSaved}
            style={{ margin: "0 16px 0" }}
          />
          <ButtonTransparent
            text={!simulation ? t("Start animation") : t("Stop animation")}
            handle={handleSimulation}
            style={{ marginTop: 0 }}
            iconPosition={"end"}
            icon={
              !simulation ? <ArrowRite /> : <div className="rounded-triangle" />
            }
          />
        </Flex>
      </Row>
    </>
  );
};

function removeChecked(project: any) {
  const copiedProject = JSON.parse(JSON.stringify(project));
  if (copiedProject.template && copiedProject.template.nodes) {
    copiedProject.template.nodes = copiedProject.template.nodes.map(
      (node: any) => {
        return {
          ...node,
          style: {
            ...node.style,
            backgroundColor: "",
            outline: "1px solid transparent",
          },
        };
      }
    );
  }

  return copiedProject;
}
