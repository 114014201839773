import { Flex } from "antd";
import { ConnectType } from "pages/project/Flow";

export const TypeConnections = ({ top = 0 }: { top?: number }) => {
  const type = [
    { value: ConnectType.PNEUMATIC, label: "Pneumatic" },
    { value: ConnectType.LIQUID, label: "Liquid" },
    { value: ConnectType.SENSOR_DATA, label: "Sensor" },
    { value: ConnectType.VALVE_DATA, label: "Valve" },
  ];

  return (
    <div style={{ padding: 15, position: "absolute", top: top }}>
      {type.map((item, index) => (
        <Flex key={index} align="center" justify="space-between" gap={10}>
          <span>{item.label}</span>{" "}
          <div style={{ background: item.value, width: 10, height: 2 }} />
        </Flex>
      ))}
    </div>
  );
};
