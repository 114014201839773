import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { IFavourites, NormalizedError, Project } from "shared/interfaces";
import { statusFetching } from "constants/types";

interface IStore<T> {
  dataTemplates: T[];
  statusTemplates: statusFetching;
  errorTemplates: NormalizedError | null;
  sorterTemplates: "asc" | "desc";
  filters: {
    searchText: string;
  };
  dataProjects: T[];
  statusProjects: statusFetching;
  sorterProjects: "asc" | "desc";
  errorProjects: NormalizedError | null;
  dataFavourites: IFavourites[];
  statusFavourites: statusFetching;
}

const initialState: IStore<Project> = {
  dataTemplates: [],
  statusTemplates: "init",
  errorTemplates: null,
  sorterTemplates: "asc",
  filters: {
    searchText: "",
  },
  dataProjects: [],
  statusProjects: "init",
  sorterProjects: "asc",
  errorProjects: null,

  dataFavourites: [],
  statusFavourites: "init",
};

export const getTemplates = createAsyncThunk(
  "dashboard/templates",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { sorterTemplates, filters } = state.dashboard;
    const queryParams = {
      search: filters?.searchText,
      templates: 1,
      sort_order: sorterTemplates,
    };

    try {
      const res = await axios.get(ENDPOINTS.projects(), {
        params: queryParams,
      });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getProjects = createAsyncThunk(
  "dashboard/projects",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { sorterProjects, filters } = state.dashboard;
    const queryParams = {
      search: filters?.searchText,
      my_projects: 1,
      sort_order: sorterProjects,
    };

    try {
      const res = await axios.get(ENDPOINTS.projects(), {
        params: queryParams,
      });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getFavourites = createAsyncThunk(
  "dashboard/favourites",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(ENDPOINTS.projectFavorites());
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateSorter: (state, action) => {
      if (action.payload === 1) {
        state.sorterTemplates =
          state.sorterTemplates === "asc" ? "desc" : "asc";
      } else {
        state.sorterProjects = state.sorterProjects === "asc" ? "desc" : "asc";
      }
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.statusTemplates = "fetching";
      })
      .addCase(getTemplates.fulfilled, (state, { payload }) => {
        const { data } = payload || {};
        state.statusTemplates = "success";
        state.dataTemplates = data;
      })
      .addCase(getTemplates.rejected, (state, { error }) => {
        state.statusTemplates = "error";
        //@ts-ignore
        state.errorTemplates = error;
      })

      .addCase(getProjects.pending, (state) => {
        state.statusProjects = "fetching";
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        const { data } = payload || {};
        state.statusProjects = "success";
        state.dataProjects = data;
      })
      .addCase(getProjects.rejected, (state, { error }) => {
        state.statusProjects = "error";
        //@ts-ignore
        state.errorProjects = error;
      })

      .addCase(getFavourites.pending, (state) => {
        state.statusFavourites = "fetching";
      })
      .addCase(getFavourites.fulfilled, (state, { payload }) => {
        const { data } = payload || {};
        state.statusFavourites = "success";
        state.dataFavourites = data;
      });
  },
});

export const { updateSorter, updateFilters } = dashboardSlice.actions;

export const selectDashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
