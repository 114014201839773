import { Button, Col, Flex, Typography } from "antd";
import { Colors } from "constants/colors";
import { useAppSelector } from "store/hooks";
import { selectProject } from "store/projectSlice";
import styled from "styled-components";
import { useContext } from "react";
import { SequenceContext } from "./SequenceModal";

const { Title } = Typography;

export interface ICondition {
  setActiveCard?: (item: number) => void;
  activeCard?: number;
}

export const DevicesAndConditions = () => {
  const { project } = useAppSelector(selectProject);
  const { nodes, edges } = project.template || {};
  const { sequence, setSequence } = useContext(SequenceContext);

  const isTargetExist = nodes?.filter((items: any) =>
    items?.node?.connectors.find((item: any) => item?.type === "target")
  );

  const addCondition = (device: any, type: string) => {
    const { type: typeDevice, id, data } = device || {};
    setSequence(
      sequence.concat(type === "device" ? {
        text: typeDevice,
        id: sequence?.length + 1,
        deviceId: id,
        type: type,
        label: data?.label,
        inputId: [],
        outputId: [],
      } : {
        text: typeDevice,
        id: sequence?.length + 1,
        type: type,
        label: data?.label,
      })
    );
  };

  return (
    <Col>
      <Title level={5} style={{ fontWeight: 700, marginBottom: 16 }}>
        Choose:
      </Title>
      <Flex vertical gap={8}>
        {conditions?.map((condition) => (
          <BlockAdd align="center">
            <Col className="block_condition">{condition.label}</Col>
            <Button
              type="link"
              className="add_condition"
              onClick={() => addCondition(condition, "condition")}
            >
              Add
            </Button>
          </BlockAdd>
        ))}
        {nodes?.map((node: any) => (
          <BlockAdd align="center">
            <Col className="block_condition block_device">{node?.type}</Col>
            <Button
              type="link"
              className="add_condition"
              onClick={() => addCondition(node, "device")}
            >
              Add
            </Button>
          </BlockAdd>
        ))}
      </Flex>
    </Col>
  );
};

const BlockAdd = styled(Flex)`
  .block_condition {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background-color: ${Colors.MainBlue500};
    height: 50px;
    width: 93px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: ${Colors.MainWait500};
    border-radius: 8px;
  }
  .add_condition {
    display: none;
  }
  .block_device {
    background-color: ${Colors.MainPurple400};
  }
  &:hover .add_condition {
    display: flex;
    cursor: pointer;
  }
  &:hover .block_condition {
    background-color: ${Colors.MainBlue200};
    color: ${Colors.MainBlue700};
    transition: 0.3s linear;
  }
  &:hover .block_device {
    background-color: ${Colors.MainPurple200};
    color: ${Colors.MainPurple600};
    transition: 0.3s linear;
  }
`;

const conditions = [
  {
    label: "Wait",
    type: "Wait",
    condition: true,
  },
  {
    label: "If",
    type: "If",
    condition: true,
  },
  {
    label: "Go to",
    type: "Go to",
    condition: true,
  },
  {
    label: "End",
    type: "End",
    condition: true,
  },
];
