import { Flex, Form, Modal, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { FormItem } from "shared/components/Form/FormItems";
import { validationErrorMessages } from "constants/form";
import {
  ButtonApprove,
  ButtonTransparent,
  getTypeRequest,
  openNotification,
} from "shared/components";
import { getProject } from "store/projectSlice";
import { Project, TypeDropDown } from "shared/interfaces";
import { ENDPOINTS } from "shared/fethers";
import axios from "axios";
import { t } from "i18next";

const { Title } = Typography;
const { required } = validationErrorMessages;

export const ModalRenameProject = ({
  open,
  setOpen,
  data,
  type,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: Project;
  type: TypeDropDown;
}) => {
  const dispatch = useAppDispatch();
  const isVIEW = type === TypeDropDown.VIEW;
  const isEDIT = type === TypeDropDown.EDIT;
  const [loading, setLoading] = useState(false);
  const { id, state_template } = data || {};
  const [form] = Form.useForm();

  const initialValue = {
    name: data?.name,
  };

  const onFinish = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        axios
          .put(ENDPOINTS.projectById(data?.id), { name: values?.name })
          .then(() => {
            if (isVIEW || isEDIT) {
              setTimeout(() => dispatch(getProject(id)), 1000);
            } else {
              getTypeRequest(dispatch, state_template);
            }
            openNotification("success");
            setOpen(!open);
          })
          .catch(() => openNotification("error"))
          .finally(() => setLoading(false));
      })
      .catch((info) => {
        console.log("Validation failed:", info);
      });
  }, [data, id, open]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === "Enter") {
        onFinish();
      }
    };
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onFinish]);

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ fontWeight: 800 }}>
          {t("Update project name")}
        </Title>
      }
      onCancel={handleCancel}
      width={400}
      footer={null}
    >
      <Form
        name="name_project"
        onFinish={onFinish}
        style={{ marginTop: 16 }}
        initialValues={initialValue}
        form={form}
      >
        <FormItem
          label={t("Project name")}
          placeholder={t("Project name")}
          formItemProps={{
            name: "name",
            rules: [{ required: true, message: required }],
            style: { marginBottom: 0 },
          }}
          colProps={{
            style: { padding: 0 },
          }}
        />
        <Flex justify="space-between" gap={16}>
          <ButtonTransparent handle={handleCancel} width="100%" />
          <ButtonApprove text="Update" loading={loading} width="100%" />
        </Flex>
      </Form>
    </Modal>
  );
};
