export enum Colors {
  MainBlue50 = "#E2F0FF",
  MainBlue100 = "#DCEDFF",
  MainBlue200 = "#9fceff",
  MainBlue500 = "#4EA5FF",
  MainBlue700 = "#1677ff",

  MainDark50 = "#EDEDF1",
  MainDark100 = "#D2D2D7",
  MainDark200 = "#A5A5B0",
  MainDark300 = "#777788",
  MainDark500 = "#1D1D39",

  MainGreen500 = "#66BB6A",

  MainWait500 = "#FFF",

  MainPurple400 = "#A17BFF",
  MainPurple200 = "#D0BFFB",
  MainPurple600 = "#6750A4",

  MainGreen100 = "#C8E6C9",
  MainGreen50 = "#E8F5E9",
  MainGreen600 = "#2E7D32",

  MainRed50 = "#FFE3E4",
  MainRed300 = "#FEAAAE",
  MainRed900 = "#AA0000",

  white = "#ffffff",
  gray1 = "#6B8899",
  gray2 = "#F0F4F7",
}
