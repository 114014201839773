import { Col, Dropdown } from "antd";
import { Colors } from "constants/colors";
import { Routes } from "constants/routes";
import { DotsIcon } from "pages/dashboard/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Project, ROLES, TypeDropDown } from "shared/interfaces";
import { getFavourites, getProjects, getTemplates } from "store/dashboardSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getProject, updateFavourites } from "store/projectSlice";
import { AppDispatch } from "store/store";
import styled from "styled-components";
import { ModalRenameProject } from "./ModalRenameProject";
import { DeleteProject } from "./DeleteProject";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { TDownloadType, ImageDownloadBlock } from "./ImageDownloadBlock";
import { GifDownloadModal } from "./GifDownloadModal";
import { HISTORY_INDEX_KEY, HISTORY_KEY } from "pages/project/Flow";
import { selectUser } from "store/userSlice";
import { openNotification } from "../Notification";
import { t } from "i18next";

enum DropDownKey {
  JSON = "JSON",
  JPEG = "JPEG",
  PNG = "PNG",
  GIF = "GIF",
  DELETE = "DELETE",
  FAVOURITES = "FAVOURITES",
  OPEN = "OPEN",
  LINK = "LINK",
  RENAME = "RENAME",
  DUPLICATE = "DUPLICATE",
  HIDE = "HIDE",
  EDIT = "EDIT",
  TEMPLATE = "TEMPLATE",
}

interface IDropDownCard {
  customDrop?: React.ReactNode;
  type: TypeDropDown;
  data: Project;
  reactFlowWrapper: any;
  favoriteStatus?: boolean;
  setFavoriteStatus?: (item: boolean) => void;
}

export const DropDownCardMenu = (props: IDropDownCard) => {
  const { data, type, customDrop, favoriteStatus, setFavoriteStatus } =
    props || {};
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [download, setDownload] = useState<TDownloadType>("");
  const [downloadGif, setDownloadGif] = useState<boolean>(false);

  const { id, state_template, is_favorite, publish } = data || {};
  const isVIEW = type === TypeDropDown.VIEW;
  const isEDIT = type === TypeDropDown.EDIT;
  const isDASHBOARD = type === TypeDropDown.DASHBOARD;

  const { roles } = useAppSelector(selectUser);

  const handleMenuClick = async ({ key }: { key: string }) => {
    if (key === DropDownKey.OPEN) {
      navigate(`/${Routes.project}/${id}`);
    }
    if (key === DropDownKey.EDIT) {
      navigate(`/${Routes.project}/edit/${id}`);
      localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
      localStorage.setItem(HISTORY_INDEX_KEY, "0");
    }
    if (key === DropDownKey.RENAME) {
      setOpen(!open);
    }
    if (key === DropDownKey.TEMPLATE) {
      await axios
        .put(ENDPOINTS.projectById(data?.id), {
          state_template: 1,
        })
        .then(() => {
          openNotification("success");
          setTimeout(() => {
            if (isVIEW || isEDIT) {
              dispatch(getProject(id));
            } else {
              dispatch(getTemplates());
              dispatch(getProjects());
            }
          }, 1000);
        })
        .catch(() => openNotification("error"));
    }
    if (key === DropDownKey.HIDE) {
      await axios
        .put(ENDPOINTS.projectById(data?.id), {
          publish: !publish ? 1 : 0,
        })
        .then(() => {
          openNotification("success");
          setTimeout(() => {
            if (isVIEW || isEDIT) {
              dispatch(getProject(id));
            } else {
              dispatch(getTemplates());
              dispatch(getProjects());
            }
          }, 1000);
        })
        .catch(() => openNotification("error"));
    }
    if (key === DropDownKey.DELETE) {
      setOpenDelete(!openDelete);
    }
    if (key === DropDownKey.FAVOURITES) {
      dispatch(
        updateFavourites({
          favorite: isDASHBOARD ? +!favoriteStatus : +!is_favorite,
          id: id,
        })
      );
      setTimeout(() => {
        if (isDASHBOARD) {
          dispatch(getFavourites());
          if (setFavoriteStatus) {
            setFavoriteStatus(!favoriteStatus);
          }
        } else {
          dispatch(getProject(id));
        }
      }, 1000);
    }
    if (key === DropDownKey.DUPLICATE) {
      await axios
        .post(ENDPOINTS.projects(), {
          ...data,
          id: null,
          name: data.name + " (Copy)",
        })
        .then(() => {
          openNotification("success");
          getTypeRequest(dispatch, state_template);
        })
        .catch(() => openNotification("error"));
    }
    if (key === DropDownKey.JSON) {
      downloadSchemaJSON(data);
    }
    if (key === DropDownKey.PNG) {
      setDownload("png");
    }
    if (key === DropDownKey.JPEG) {
      setDownload("jpeg");
    }
    if (key === DropDownKey.LINK) {
      handleCopyLink(String(id));
    }
    if (key === DropDownKey.GIF) {
      setDownloadGif(true);
    }
  };
  const items = itemsData({
    type: type,
    data: data,
    favoriteStatus: isDASHBOARD ? !!favoriteStatus : is_favorite,
    roles: roles,
  });

  return (
    <>
      <ImageDownloadBlock
        data={data}
        download={download}
        setDownload={setDownload}
      />
      <GifDownloadModal
        data={data}
        download={downloadGif}
        setDownload={setDownloadGif}
      />
      <ModalRenameProject
        open={open}
        setOpen={() => setOpen(!open)}
        data={data}
        type={type}
      />
      <DeleteProject
        open={openDelete}
        setOpen={() => setOpenDelete(!openDelete)}
        data={data}
        type={type}
      />
      <Dropdown
        menu={{ items, onClick: handleMenuClick }}
        placement="bottomRight"
      >
        {customDrop ? (
          customDrop
        ) : (
          <ButtonStyle
            onClick={(e) => e.preventDefault()}
            style={{ backgroundColor: isVIEW ? Colors.MainDark50 : "" }}
          >
            <DotsIcon />
          </ButtonStyle>
        )}
      </Dropdown>
    </>
  );
};

const itemsData = (props: {
  type: TypeDropDown;
  data: Project;
  favoriteStatus: boolean;
  roles?: string[];
}): any => {
  const { data, type, favoriteStatus, roles } = props || {};
  const isDashboard = type === TypeDropDown.DASHBOARD;
  const isEdit = type === TypeDropDown.EDIT;
  const isSuperAdmin = roles?.includes(ROLES.SUPER_ADMIN);
  const isAdmin = roles?.includes(ROLES.ADMIN);
  const isUser = roles?.includes(ROLES.USER);
  const showButton =
    isSuperAdmin || isAdmin || (!data?.state_template && isUser);

  return [
    (isDashboard || isEdit) && {
      key: DropDownKey.OPEN,
      label: t("Preview"),
    },
    showButton &&
      !isEdit && {
        key: DropDownKey.EDIT,
        label: t("Edit"),
      },
    showButton && {
      key: DropDownKey.RENAME,
      label: t("Rename"),
    },
    showButton && {
      key: DropDownKey.DUPLICATE,
      label: t("Duplicate"),
    },
    {
      key: DropDownKey.FAVOURITES,
      label: !favoriteStatus
        ? t("Add to favourites")
        : t("Remove from favourites"),
    },
    (isSuperAdmin || isAdmin) &&
      data?.state_template && {
        key: DropDownKey.HIDE,
        label: data?.publish === 1 ? t("Unpublish") : t("Publish"),
      },
    (isSuperAdmin || isAdmin) &&
      !data?.state_template && {
        key: DropDownKey.TEMPLATE,
        label: t("Make Template"),
      },
    {
      key: "6",
      label: t("Export..."),
      children: [
        {
          key: DropDownKey.LINK,
          label: t("As a link"),
        },
        {
          key: DropDownKey.PNG,
          label: "PNG",
        },
        {
          key: DropDownKey.JPEG,
          label: "JPEG",
        },
        {
          key: DropDownKey.JSON,
          label: "JSON",
        },
        {
          key: DropDownKey.GIF,
          label: "GIF",
        },
      ],
    },
    showButton && {
      key: DropDownKey.DELETE,
      label: t("Delete"),
    },
  ];
};

export const getTypeRequest = (
  dispatch: AppDispatch,
  state_template: number
) => {
  setTimeout(() => {
    dispatch(state_template === 1 ? getTemplates() : getProjects());
    dispatch(getFavourites());
  }, 1000);
};

export const ButtonStyle = styled(Col)`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  padding: 4px;
  background-color: ${Colors.MainWait500};
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const downloadSchemaJSON = (data: Project) => {
  const { name } = data || {};
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = name + ".json";
  link.click();
  URL.revokeObjectURL(url);
};

export const handleCopyLink = async (id: string) => {
  await axios.post(ENDPOINTS.generateCopyLink(id)).then((item: any) => {
    const { copy_link } = item?.data || {};
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(copy_link)
        .then(() => {
          openNotification("success", t("Link copied to clipboard"));
          console.log("Text copied to clipboard");
        })
        .catch((err) => {
          openNotification("error", t("Oops, unable to copy"));
          console.error("Could not copy text: ", err);
        });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = copy_link;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        console.log("Text copied to clipboard using fallback method");
        openNotification("success", t("Link copied to clipboard"));
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        openNotification("error", t("Oops, unable to copy"));
      }
      document.body.removeChild(textarea);
    }
  });
};
