import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { IStore } from "shared/interfaces";
import _ from "lodash";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { calcSorter } from "./manageAdminSlice";

const initialState: IStore<any> = {
  data: [],
  fetchStatus: "init",
  pagination: {
    currentPage: 1,
    total: 1,
    perPage: 10,
  },
  sorter: {},
  filters: {},
  fetchError: null,
};

export const addUserManager = createAsyncThunk(
  "manageUser/ForgotPassword",
  async ({ values }: any, { rejectWithValue }) => {
    try {
      const req = await axios.post(ENDPOINTS.users(), values);
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteUserManager = createAsyncThunk(
  "manageUser/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const req = await axios.delete(ENDPOINTS.userById(id));
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateUserManager = createAsyncThunk(
  "manageUser/updateUser",
  async (values: any, { rejectWithValue }) => {
    const { id, data } = values;
    try {
      const req = await axios.put(ENDPOINTS.userById(id), data);
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getData = createAsyncThunk(
  "manageUser/Data",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { currentPage, perPage } = state.manageUser?.pagination || {};
    const { sorter, filters } = state.manageUser;
    const { sortBy, sortOrder } = sorter || {};

    const queryParams = {
      perPage: perPage,
      page: currentPage,
      sortBy: calcSorter(sortOrder) ? sortBy : null,
      sortOrder: calcSorter(sortOrder),
      search: filters?.searchText,
    };

    try {
      const res = await axios.get(ENDPOINTS.users(), { params: queryParams });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const manageUserSlice = createSlice({
  name: "manageUser",
  initialState,
  reducers: {
    updatePagination: (state, action) => {
      state.pagination = action.payload;
    },
    updateSorter: (state, action) => {
      state.sorter = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.fetchStatus = "fetching";
      })
      .addCase(getData.fulfilled, (state, { payload }) => {
        const { data } = payload || {};
        state.fetchStatus = "success";
        state.data = data.data;
        const { total, perPage, currentPage } = data?.pagination || {};
        state.pagination = {
          ...state.pagination,
          total,
          perPage,
          currentPage,
        };
      })
      .addCase(getData.rejected, (state, { error }) => {
        state.fetchStatus = "error";
        //@ts-ignore
        state.fetchError = error;
      });
  },
});

export const { updatePagination, updateSorter, updateFilters } =
  manageUserSlice.actions;

export const selectManageUser = (state: RootState) => state.manageUser;

export default manageUserSlice.reducer;
