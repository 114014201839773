import { Col, Flex, Form, Typography } from "antd";
import { EmptyData, SequenceContext } from "./SequenceModal";
import { validationErrorMessages } from "constants/form";
import { FormItem } from "shared/components";
import { CustomSelect } from "../side-bar/DevicesPanel";
import { useContext, useEffect, useState } from "react";
import { ICondition } from "./DevicesAndConditions";
import { selectProject } from "store/projectSlice";
import { useAppSelector } from "store/hooks";

const { Text, Title } = Typography;
const { required } = validationErrorMessages;

export const ListSettings = (props: ICondition) => {
  const [form] = Form.useForm();
  const { activeCard } = props;
  const { sequence } = useContext(SequenceContext);
  const activeData = sequence?.find((item: any) => item.id === activeCard);

  const isWait = activeData?.text === "Wait";
  const isIF = activeData?.text === "If";
  const isDevice = activeData?.type === "device";
  const isGoTo = activeData?.text === "Go to";

  const onFinish = (values: any) => {
    console.log(111, values);
  };
  return (
    <Col
      style={{
        height: "100%",
      }}
    >
      <Title level={5} style={{ fontWeight: 700, marginBottom: 16 }}>
        List:
      </Title>
      {!sequence?.length && <EmptyData />}
      {activeCard && (
        <Form
          name="condition"
          onFinish={onFinish}
          style={{ marginTop: 16 }}
          form={form}
        >
          {isDevice && <DeviceCondition data={activeData} />}
          {isWait && <WaitCondition data={activeData} />}
          {isGoTo && <GotoCondition data={activeData} />}
        </Form>
      )}
    </Col>
  );
};

const WaitCondition = ({ data }: any) => {
  const { sequence, setSequence } = useContext(SequenceContext);

  const handleTimer = (value: any) => {
    setSequence((prevData: any) =>
      prevData.map((item: any) =>
        item?.id === data.id
          ? {
              ...item,
              timer: Number(value),
              typeCondition: "wait",
            }
          : item
      )
    );
  };
  return (
    <Flex style={{ width: 100 }}>
      <FormItem
        label="Seconds"
        placeholder="Seconds"
        formItemProps={{
          name: `seconds_${data?.id}`,
        }}
        inputProps={{
          onChange: (e) => handleTimer(e.target.value),
        }}
      />
    </Flex>
  );
};

const DeviceCondition = ({ data }: any) => {
  const { project } = useAppSelector(selectProject);
  const { nodes } = project?.template || {};
  const [selectedChanel, setSelectedChanel] = useState<number[]>([]);
  const activeDevice = nodes?.find((item: any) => item?.id === data?.deviceId);
  const activeConnectors = activeDevice?.node?.connectors?.filter(
    (item: any) => item?.type === "target"
  );
  const { setSequence } = useContext(SequenceContext);

  const onChange = (value: any) => {
    const names = options
      .filter((channel: any) => value.includes(channel.id))
      .map((channel: any) => channel.name)
      .join(", ");

    setSelectedChanel(value);
    setSequence((prevData: any) =>
      prevData.map((item: any) =>
        item?.id === data?.id
          ? {
              ...item,
              outputLabel: names,
              outputId: value,
            }
          : { ...item, label: item.label }
      )
    );
  };

  const onChangeLabel = (id: number, newLabel: string) => {
    setSequence((prevData: any) =>
      prevData.map((item: any) =>
        item?.id === id ? { ...item, label: newLabel } : item
      )
    );
  };

  const options = activeConnectors?.map((item: any, index: number) => {
    return {
      name: `Chanel ${index + 1}`,
      id: item?.id,
    };
  });

  return (
    <Flex style={{ width: "100%" }} vertical>
      <FormItem
        label="Instrument"
        placeholder="Instrument"
        formItemProps={{
          name: `label_${data?.id}`,
        }}
        inputProps={{
          defaultValue: data?.label,
          onChange: (e) => onChangeLabel(data?.id, e.target.value),
        }}
      />
      <Col span="24">
        <CustomSelect
          selectedType={selectedChanel}
          onChange={(value: number[]) => onChange(value)}
          title="Chanel"
          typeOptrions={options}
        />
      </Col>
    </Flex>
  );
};

const GotoCondition = ({ data }: any) => {
  const { project } = useAppSelector(selectProject);
  const { nodes } = project?.template || {};
  const [selectedChanel, setSelectedChanel] = useState<number[]>([]);
  const activeDevice = nodes?.find((item: any) => item?.id === data?.deviceId);
  const activeConnectors = activeDevice?.node?.connectors?.filter(
    (item: any) => item?.type === "target"
  );
  const { setSequence } = useContext(SequenceContext);

  const onChange = (value: any) => {
    const names = options
      .filter((channel: any) => value.includes(channel.id))
      .map((channel: any) => channel.name)
      .join(", ");

    setSelectedChanel(value);
    setSequence((prevData: any) =>
      prevData.map((item: any) =>
        item?.id === data?.id
          ? {
              ...item,
              outputLabel: names,
              outputId: value,
            }
          : { ...item, label: item.label }
      )
    );
  };

  const onChangeLabel = (id: number, newLabel: string) => {
    setSequence((prevData: any) =>
      prevData.map((item: any) =>
        item?.id === id ? { ...item, label: newLabel } : item
      )
    );
  };

  const options = activeConnectors?.map((item: any, index: number) => {
    return {
      name: `Chanel ${index + 1}`,
      id: item?.id,
    };
  });

  return (
    <Flex style={{ width: "100%" }} vertical>
      <FormItem
        label="Instrument"
        placeholder="Instrument"
        formItemProps={{
          name: `label_${data?.id}`,
        }}
        inputProps={{
          defaultValue: data?.label,
          onChange: (e) => onChangeLabel(data?.id, e.target.value),
        }}
      />
      <Col span="24">
        <CustomSelect
          selectedType={selectedChanel}
          onChange={(value: number[]) => onChange(value)}
          title="Chanel"
          typeOptrions={options}
        />
      </Col>
    </Flex>
  );
};