import { Col, Flex, Typography } from "antd";
import {
  DashboardIcon,
  MyProjectsIcon,
  SearchIcon,
  TemplatesIcon,
} from "pages/dashboard/icons";
import { MyProjectsBlockMobile } from "pages/my-projects";
import { TemplatesBlockMobile } from "pages/templates";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getFavourites, getProjects, getTemplates } from "store/dashboardSlice";
import { AppDispatch } from "store/store";

const { Title, Text } = Typography;

export const HomeMobile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getFavourites());
    dispatch(getProjects());
    dispatch(getTemplates());
  }, []);

  return (
    <>
      <Col
        style={{
          padding: "24px 16px",
          height: "calc(20px + 100vh)",
          paddingTop: "89px",
        }}
      >
        <Col>
          <Title
            level={3}
            style={{ fontWeight: 800, margin: 0, whiteSpace: "nowrap" }}
          >
            {t("My dashboard")}
          </Title>
        </Col>
        <TemplatesBlockMobile title={t("Templates")} />
        <MyProjectsBlockMobile title={t("My projects")} />
      </Col>
      <Flex
        style={{
          width: "100%",
          padding: "8px 16px",
          position: "absolute",
          bottom: 0,
          background: "white",
        }}
        justify="space-between"
        align="center"
      >
        <Flex
          vertical
          align="center"
          style={{ height: "40px" }}
          justify="space-between"
        >
          <DashboardIcon />
          <Text style={{ fontSize: 10 }}>{t("Dashboard")}</Text>
        </Flex>
        <Flex
          vertical
          align="center"
          style={{ height: "40px", paddingTop: 3 }}
          justify="space-between"
        >
          <SearchIcon />
          <Text style={{ fontSize: 10 }}>{t("Search")}</Text>
        </Flex>
        <Flex
          vertical
          align="center"
          style={{ height: "40px" }}
          justify="space-between"
        >
          <TemplatesIcon />
          <Text style={{ fontSize: 10 }}>{t("Templates")}</Text>
        </Flex>
        <Flex
          vertical
          align="center"
          style={{ height: "40px" }}
          justify="space-between"
        >
          <MyProjectsIcon />
          <Text style={{ fontSize: 10 }}>{t("My projects")}</Text>
        </Flex>
      </Flex>
    </>
  );
};
