import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store/store";
import { Route, Routes } from "react-router-dom";
import { Routes as Link } from "constants/routes";
import { AuthenticationGuard } from "shared/components/router/AuthenticationGuard";
import {
  LoginPage,
  SignUpPage,
  ForgotPasswordPage,
  HomePage,
  ResetPasswordPage,
  VerifyEmail,
  Project,
  ProjectPreview,
} from "./pages";
import { getUserInfo } from "store/userSlice";
import { MyProfilePage } from "pages/my-profile";
import "./App.css";
import "shared/locale/i18n";
import { BaseContainer } from "shared/components";

const routes = [
  {
    path: Link.base,
    component: <LoginPage />,
  },
  {
    path: Link.signUp,
    component: <SignUpPage />,
  },
  {
    path: Link.forgotPassword,
    component: <ForgotPasswordPage />,
  },
  {
    path: Link.resetPassword,
    component: <ResetPasswordPage />,
  },
  {
    path: Link.verifyEmail,
    component: <VerifyEmail />,
  },
  {
    path: `${Link.project}/share`,
    component: <ProjectPreview />,
  },
  {
    path: "*",
    component: <LoginPage />,
  },
];

const getAuthorizedRoutes = () => {
  return [
    {
      path: Link.base,
      component: <HomePage />,
    },
    {
      path: Link.myProfilePage,
      component: <MyProfilePage />,
    },
    {
      path: `${Link.project}/:id`,
      component: <ProjectPreview />,
    },
    {
      path: `${Link.project}/edit/:id`,
      component: <Project />,
    },
    {
      path: "*",
      component: <HomePage />,
    },
  ];
};

function App() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const authorizedRoutes = getAuthorizedRoutes();

  return (
    <div className="root_block main__layout_light">
      <AuthenticationGuard
        render={({ isAuthenticated }: { isAuthenticated: boolean }) =>
          isAuthenticated ? (
            <BaseContainer>
              <Routes>
                {authorizedRoutes.map((route, key) => {
                  return (
                    <Route
                      key={key}
                      path={route.path}
                      element={route.component}
                    />
                  );
                })}
              </Routes>
            </BaseContainer>
          ) : (
            <BaseContainer>
              <Routes>
                {routes.map((route, key) => {
                  return (
                    <Route
                      key={key}
                      path={route.path}
                      element={route.component}
                    />
                  );
                })}
              </Routes>
            </BaseContainer>
          )
        }
      />
    </div>
  );
}

export default App;
