import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { IStore } from "shared/interfaces";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";

const initialState: IStore<any> = {
  data: [],
  fetchStatus: "init",
  pagination: {
    currentPage: 1,
    total: 1,
    perPage: 10,
  },
  sorter: {},
  filters: {},
  fetchError: null,
};

export const calcSorter = (sorter: string) => {
  if (sorter === "ascend") return "asc";
  if (sorter === "descend") return "desc";
  return null;
};

export const addAdminManager = createAsyncThunk(
  "manageAdmins/ForgotPassword",
  async ({ values }: any, { rejectWithValue }) => {
    try {
      const req = await axios.post(ENDPOINTS.admins(), values);
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteAdminManager = createAsyncThunk(
  "manageAdmins/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      const req = await axios.delete(ENDPOINTS.adminById(id));
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateAdminManager = createAsyncThunk(
  "manageAdmins/updateAdmins",
  async (values: any, { rejectWithValue }) => {
    const { id, data } = values;
    try {
      const req = await axios.put(ENDPOINTS.adminById(id), data);
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getData = createAsyncThunk(
  "manageAdmins/Data",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { currentPage, perPage } = state.manageAdmins?.pagination || {};
    const { sorter, filters } = state.manageAdmins;
    const { sortBy, sortOrder } = sorter || {};
    const queryParams = {
      perPage: perPage,
      page: currentPage,
      sortBy: calcSorter(sortOrder) ? sortBy : null,
      sortOrder: calcSorter(sortOrder),
      search: filters?.searchText,
    };

    try {
      const res = await axios.get(ENDPOINTS.admins(), { params: queryParams });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const manageAdminsSlice = createSlice({
  name: "manageAdmins",
  initialState,
  reducers: {
    updatePagination: (state, action) => {
      state.pagination = action.payload;
    },
    updateSorter: (state, action) => {
      state.sorter = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.fetchStatus = "fetching";
      })
      .addCase(getData.fulfilled, (state, { payload }) => {
        const { data } = payload || {};
        state.fetchStatus = "success";
        state.data = data.data;
        const { total, perPage, currentPage } = data?.pagination || {};
        state.pagination = {
          ...state.pagination,
          total,
          perPage,
          currentPage,
        };
      })
      .addCase(getData.rejected, (state, { error }) => {
        state.fetchStatus = "error";
        //@ts-ignore
        state.fetchError = error;
      });
  },
});

export const { updatePagination, updateSorter, updateFilters } =
  manageAdminsSlice.actions;

export const selectManageAdmins = (state: RootState) => state.manageAdmins;

export default manageAdminsSlice.reducer;
