const language = localStorage.getItem("language") || "en";

export const validationErrorMessages = {
  required: language === "en" ? "required field" : "champ obligatoire",
  password: "at least 1 lowercase, 1 uppercase, 1 special symbol, 1 number",
  email:
    language === "en"
      ? "is not a valid email"
      : "n'est pas une adresse e-mail valide",
  phone:
    language === "en"
      ? "is not a valid phone number"
      : "n'est pas un numéro de téléphone valide",
};
