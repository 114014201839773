import { selectUser } from "store/userSlice";
import { useAppSelector } from "store/hooks";

interface Props {
  render: (props: { isAuthenticated: boolean }) => React.ReactNode;
}

export const AuthenticationGuard = ({ render }: Props) => {
  const user = useAppSelector(selectUser);

  if (user?.status === "fetching" || user?.status === "init") {
    return null;
  }

  return (
    <>
      {render({
        isAuthenticated: user?.status === "success",
      })}
    </>
  );
};
