import { Empty, Flex, Input, Row, Spin, Typography } from "antd";
import { ChangeEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getTemplates,
  selectDashboard,
  updateSorter,
  updateFilters,
} from "store/dashboardSlice";
import { useAppSelector } from "store/hooks";
import { AppDispatch } from "store/store";
import "@xyflow/react/dist/style.css";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { Project, ROLES } from "shared/interfaces";
import { SorterText } from "pages/dashboard";
import { ProjectCard } from "pages/dashboard/ProjectCard";
import { createProject } from "store/projectSlice";
import { ButtonApprove } from "shared/components";
import { Routes } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { HISTORY_INDEX_KEY, HISTORY_KEY } from "pages/project/Flow";
import { selectUser } from "store/userSlice";
import { ProjectCardMobile } from "pages/dashboard/ProjectCardMobile";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export const Templates = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  const handleChangeSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFilters({ searchText: e.target.value }));
    dispatch(getTemplates());
  }, 1000);

  return (
    <>
      <Flex justify="space-between">
        <Title level={3} style={{ fontWeight: 800, margin: 0 }}>
          {t("Templates")}
        </Title>
        <Input
          placeholder="Search by project name"
          prefix={<SearchOutlined />}
          style={{ width: 280, borderRadius: 50, height: 40 }}
          onChange={handleChangeSearch}
          allowClear
        />
      </Flex>
      <TemplatesBlock title={t("Projects")} />
    </>
  );
};

interface ITemplatesBlock {
  title: string;
}

export const TemplatesBlock = ({ title }: ITemplatesBlock) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { roles } = useAppSelector(selectUser);
  const isSuperAdmin = roles.includes(ROLES.SUPER_ADMIN);
  const isAdmin = roles.includes(ROLES.ADMIN);
  const { dataTemplates, statusTemplates, sorterTemplates } =
    useAppSelector(selectDashboard);
  const isLoading =
    statusTemplates === "fetching" || statusTemplates === "init";
  const { t } = useTranslation();

  const createNew = () => {
    dispatch(createProject({ state_template: 1 })).then((item: any) => {
      const { id } = item?.payload?.data || {};
      navigate(`/${Routes.project}/edit/${id}`);
      localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
      localStorage.setItem(HISTORY_INDEX_KEY, "0");
    });
  };

  const sortFn = () => {
    dispatch(updateSorter(1));
    dispatch(getTemplates());
  };

  return (
    <>
      <Flex justify="space-between" align="center" style={{ marginTop: 24 }}>
        <Title level={5} style={{ fontWeight: 800 }}>
          {title}
        </Title>
        <Flex align="center">
          <SorterText type={sorterTemplates} onClick={sortFn} />
          {(isSuperAdmin || isAdmin) && (
            <ButtonApprove
              style={{ marginTop: 0, marginLeft: 16 }}
              text={t("Create new template")}
              handle={createNew}
            />
          )}
        </Flex>
      </Flex>
      <Row
        style={{
          minHeight: 275,
        }}
        gutter={16}
      >
        {isLoading && (
          <Spin
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
        {!isLoading &&
          dataTemplates?.map((item: Project, index: number) => (
            <ProjectCard key={index} data={item} />
          ))}
        {dataTemplates?.length === 0 && !isLoading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("No templates")}
            style={{ margin: "auto" }}
          />
        )}
      </Row>
    </>
  );
};

export const TemplatesBlockMobile = ({ title }: ITemplatesBlock) => {
  const dispatch = useDispatch<AppDispatch>();
  const { dataTemplates, statusTemplates, sorterTemplates } =
    useAppSelector(selectDashboard);
  const isLoading =
    statusTemplates === "fetching" || statusTemplates === "init";
  const { t } = useTranslation();

  const sortFn = () => {
    dispatch(updateSorter(1));
    dispatch(getTemplates());
  };
  return (
    <>
      <Flex justify="space-between" align="center" style={{ marginTop: 24 }}>
        <Title level={5} style={{ fontWeight: 800 }}>
          {title}
        </Title>
        <SorterText type={sorterTemplates} onClick={sortFn} />
      </Flex>
      <Row
        style={{
          minHeight: 100,
          margin: 0,
        }}
        gutter={16}
      >
        {isLoading && (
          <Spin
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
        {!isLoading &&
          dataTemplates?.map((item: Project, index: number) => (
            <ProjectCardMobile key={index} data={item} />
          ))}
        {dataTemplates?.length === 0 && !isLoading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("No templates")}
            style={{ margin: "auto" }}
          />
        )}
      </Row>
    </>
  );
};
