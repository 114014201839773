import {
  Col,
  Typography,
  Divider,
  GetProp,
  MenuProps,
  Menu,
  Form,
  Button,
  FormProps,
  Flex,
} from "antd";
import axios from "axios";
import { Colors } from "constants/colors";
import { validationErrorMessages } from "constants/form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ErrorText, openNotification } from "shared/components";
import { FormItem } from "shared/components/Form/FormItems";
import { ENDPOINTS } from "shared/fethers";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserInfo, initSuccess, logout, selectUser } from "store/userSlice";
import styled from "styled-components";

const { Text, Title } = Typography;
type MenuItem = GetProp<MenuProps, "items">[number];

export const MyProfilePage = () => {
  const [render, updateRender] = useState(1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(selectUser);
  const { t } = useTranslation();

  const items: MenuItem[] = [
    {
      key: "1",
      label: t("Contact info"),
    },
    {
      key: "2",
      label: t("Password"),
    },
  ];

  const logOut = () => {
    dispatch(logout());
    navigate("/");
    dispatch(getUserInfo()).finally(() => window.location.reload());
  };

  const handleMenuClick = ({ key }: { key: string }) => {
    updateRender(Number(key));
  };

  return (
    <Col style={{ height: "calc(100vh - 65px)" }}>
      <Col style={{ padding: 32 }}>
        <Title
          style={{ color: Colors.MainBlue500, margin: 0, fontWeight: 800 }}
          level={4}
        >
          {t("My Profile")}
        </Title>
        <Title style={{ margin: 0, fontSize: 48, fontWeight: 800 }}>
          {t("Hello")}
          {userInfo?.first_name ? ", " + userInfo?.last_name : ""}
        </Title>
      </Col>

      <Divider type="horizontal" style={{ margin: 0 }} />
      <Flex style={{ height: "calc(100% - 151px)" }}>
        <Flex style={{ marginTop: 32 }} vertical justify="space-between">
          <MenuStyled
            style={{ width: 235, borderInlineEnd: 0 }}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            items={items}
            onClick={handleMenuClick}
          />
          <Col style={{ padding: 32 }}>
            <Link
              to="/"
              onClick={logOut}
              style={{ color: Colors.MainDark500, fontSize: 16 }}
            >
              {t("Log out")}
            </Link>
          </Col>
        </Flex>
        <Divider type="vertical" style={{ height: "100%", margin: 0 }} />
        <Col style={{ padding: 32 }}>{components[render]}</Col>
      </Flex>
    </Col>
  );
};

export const MenuStyled = styled(Menu)`
  .ant-menu-submenu-title {
    height: 48px;
    font-size: 16px;
    border-radius: 0;
    margin-inline: 0;
    color: ${Colors.MainDark500} !important;
    margin: 0;
    height: 48px !important;
    width: 100%;
  }
  .ant-menu-sub.ant-menu-inline {
    background-color: transparent !important;
  }
  .ant-menu-item-selected {
    color: ${Colors.MainDark500};
    font-weight: 700;
    background-color: ${Colors.MainBlue50};
  }

  .ant-menu-item {
    margin: 0;
    width: 100%;
    border-radius: 0;
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 24px !important;
  }
  .ant-menu-submenu-title {
    padding-left: 24px !important;
  }
  .child-menu_item {
    padding-left: 32px !important;
  }
`;

const { required, email } = validationErrorMessages;

type FieldTypeInfo = {
  email: string;
  first_name: string;
  last_name: string;
};

const ContactInfo = () => {
  const dispatch = useDispatch();
  const { userInfo } = useAppSelector(selectUser);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onFinish: FormProps<FieldTypeInfo>["onFinish"] = async (values) => {
    setError(null);
    setLoading(true);
    await axios
      .put(ENDPOINTS.userInfo(), values)
      .then((values: any) => {
        dispatch(initSuccess(values));
        openNotification("success", t("Contact info changed"));
      })
      .catch((e) => {
        setError(e?.response?.data?.message);
        openNotification("error", t("Contact info change failed"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { email: emailValue, first_name, last_name } = userInfo || {};
  const initialValue = {
    email: emailValue,
    first_name: first_name,
    last_name: last_name,
  };

  return (
    <Form
      name="change-name"
      initialValues={initialValue}
      onFinish={onFinish}
      style={{ width: 296, marginTop: 24 }}
    >
      <FormItem
        label="Email"
        placeholder="Email"
        formItemProps={{
          name: "email",
          rules: [
            {
              min: 1,
              max: 240,
              type: "email",
              message: email,
            },
            { required: true, message: required },
          ],
        }}
        disabled
      />
      <Text
        style={{
          color: Colors.MainDark200,
          fontSize: 12,
          display: "block",
          marginTop: -8,
          marginBottom: 16,
        }}
      >
        {t("You cannot change your email")}
      </Text>
      <FormItem
        label={t("First name")}
        placeholder={t("First name")}
        formItemProps={{
          name: "first_name",
          rules: [
            { required: true, message: required },
            { min: 2, message: t("Must be at least 2 characters") },
            { max: 30, message: t("Cannot be more than 30 characters") },
          ],
        }}
      />
      <FormItem
        label={t("Last name")}
        placeholder={t("Last name")}
        formItemProps={{
          name: "last_name",
          rules: [
            { required: true, message: required },
            { min: 2, message: t("Must be at least 2 characters") },
            { max: 30, message: t("Cannot be more than 30 characters") },
          ],
        }}
      />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{
            borderRadius: 50,
            backgroundColor: Colors.MainBlue500,
            fontSize: 16,
            height: 40,
            marginTop: 8,
          }}
          loading={loading}
        >
          {t("Save changes")}
        </Button>
      </Form.Item>
      <Col>
        <ErrorText text={error ?? ""} />
      </Col>
    </Form>
  );
};

type FieldTypePassword = {
  old_password: string;
  password: string;
  password_confirmation: string;
};

const Password = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onFinish: FormProps<FieldTypePassword>["onFinish"] = async (values) => {
    setError(null);
    setLoading(true);
    await axios
      .put(ENDPOINTS.userPassword(), values)
      .then(() => {
        openNotification("success", t("Password changed"));
        form.resetFields();
      })
      .catch((e) => {
        setError(e?.response?.data?.message);
        openNotification("error", t("Password change failed"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      name="change-password_user"
      onFinish={onFinish}
      style={{ width: 296, marginTop: 24 }}
      form={form}
    >
      <FormItem
        label={t("Old password")}
        placeholder={t("Old password")}
        formItemProps={{
          name: "old_password",
          rules: [{ required: true, message: required }],
        }}
        type="password"
      />
      <FormItem
        label={t("New password")}
        placeholder={t("New password")}
        formItemProps={{
          name: "password",
          rules: [{ required: true, message: required }],
        }}
        type="password"
      />
      <FormItem
        label={t("Repeat new password")}
        placeholder={t("Repeat new password")}
        formItemProps={{
          name: "password_confirmation",
          rules: [{ required: true, message: required }],
        }}
        type="password"
      />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{
            borderRadius: 50,
            backgroundColor: Colors.MainBlue500,
            fontSize: 16,
            height: 40,
            marginTop: 8,
          }}
          loading={loading}
        >
          {t("Save changes")}
        </Button>
      </Form.Item>
      <Col>
        <ErrorText text={error ?? ""} />
      </Col>
    </Form>
  );
};

type Components = {
  [key: number]: JSX.Element;
};

const components: Components = {
  1: <ContactInfo />,
  2: <Password />,
};
