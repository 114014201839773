import { Form, FormProps, Typography } from "antd";
import { Routes } from "constants/routes";
import { validationErrorMessages } from "constants/form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { resetPassword } from "store/userSlice";
import {
  ButtonStyled,
  FormItem,
  TitleH1,
  Container,
  ErrorText,
} from "shared/components";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;
const { required } = validationErrorMessages;

type FieldType = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
};

export const ResetPasswordPage = () => {
  const [isReset, setResetStatus] = useState(false);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const query = new URLSearchParams(window.location.search);
  const tokenId = query.get("token_id");
  const email = query.get("email");

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const data = {
      email: email,
      token: tokenId,
      password: values?.password,
      password_confirmation: values?.password_confirmation,
    };
    setResetStatus(false);
    setLoading(true);
    dispatch(resetPassword(data))
      .unwrap()
      .then(() => setResetStatus(true))
      .catch((e) => setError(e?.message))
      .finally(() => {
        setLoading(false);
      });
  };

  if (isReset) {
    return (
      <Congrats
        text={t("Your password has been successfully changed.")}
        buttonText={t("Go to Log in")}
      />
    );
  }

  return (
    <Container>
      <TitleH1 text={t("Reset password")} />
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ width: "100%", marginTop: 24 }}
      >
        <FormItem
          label={t("New password")}
          placeholder={t("New password")}
          formItemProps={{
            name: "password",
            rules: [{ required: true, message: required }],
          }}
          type="password"
        />
        <FormItem
          label={t("Repeat new password")}
          placeholder={t("Repeat new password")}
          formItemProps={{
            name: "password_confirmation",
            rules: [{ required: true, message: required }],
          }}
          type="password"
        />
        <Form.Item>
          <ButtonStyled
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            {t("Update password")}
          </ButtonStyled>
        </Form.Item>

        <ErrorText text={error ?? ""} />

        <Form.Item style={{ textAlign: "center" }}>
          <Link style={{ fontSize: 16 }} to={`/${Routes.login}`}>
            {t("Back to log in")}
          </Link>
        </Form.Item>
      </Form>
    </Container>
  );
};

interface ICongrats {
  text: string;
  buttonText: string;
}

export const Congrats = ({ text, buttonText }: ICongrats) => {
  const navigate = useNavigate();

  return (
    <Container>
      <TitleH1 text="Congrats" />
      <Paragraph
        style={{ textAlign: "center", fontSize: 16, margin: "8px 0 24px" }}
      >
        {text}
      </Paragraph>

      <ButtonStyled
        type="primary"
        htmlType="submit"
        className="login-form-button"
        onClick={() => navigate("/")}
      >
        {buttonText}
      </ButtonStyled>
    </Container>
  );
};
