import { Button, Col, Flex, Input, Spin, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Colors } from "constants/colors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { debounce } from "lodash";
import { ChangeEvent, useState } from "react";
import { AddPlus, DeleteBasket } from "shared/icons";
import {
  getData,
  selectSequences,
  updateFilters,
  deleteSequences,
  playSequences,
} from "store/sequencesSlice";
import { PlayIcon, EditIcon, StopIcon } from "../icons";
import { SequenceModal } from "./SequenceModal";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { t } from "i18next";

const { Text, Title } = Typography;

export const SequenceList = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { data, fetchStatus } = useAppSelector(selectSequences);
  const isLoading = fetchStatus === "fetching" || fetchStatus === "init";
  const [editSequence, setEditSequence] = useState<any>(null);
  const { activeSequences } = useAppSelector(selectSequences);
  const [playId, setPlayId] = useState<number | null>(null);
  const { id } = useParams();

  const handleChangeSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFilters({ searchText: e.target.value }));
    dispatch(getData(id));
  }, 1000);

  const handleDelete = (num: number) => {
    dispatch(deleteSequences(num));
    setTimeout(() => dispatch(getData(id)), 1000);
  };

  const startSequences = (num: number) => {
    const active = data?.find((item: any) => item?.id === num);
    if (activeSequences?.length === 0) {
      dispatch(playSequences(active?.parameters || []));
      setPlayId(num);
    } else {
      dispatch(playSequences([]));
      setPlayId(null);
    }
  };

  const editSequences = (num: number) => {
    setOpen(true);
    const active = data?.find((item: any) => item?.id === num);
    setEditSequence(active);
  };

  return (
    <>
      <SequenceModal open={open} setOpen={setOpen} data={editSequence} />
      <Flex style={{ marginTop: 8 }} align="center" gap={16}>
        <Input
          placeholder={t("Search by sequence name")}
          prefix={<SearchOutlined />}
          style={{ borderRadius: 50, height: 40 }}
          onChange={handleChangeSearch}
          allowClear
        />
        <Button
          type="link"
          icon={<AddPlus />}
          iconPosition="start"
          style={{
            color: Colors.MainBlue500,
            fontSize: 16,
            fontWeight: 700,
            padding: 0,
          }}
          onClick={() => setOpen(true)}
        >
          {t("sequence")}
        </Button>
      </Flex>
      <Col style={{ marginTop: 24 }} className="scroll_alements">
        {isLoading && (
          <Spin
            style={{
              marginTop: 100,
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
        {!!data?.length &&
          !isLoading &&
          data?.map((item: any, index: number) => (
            <CardStyled
              key={index}
              style={{
                backgroundColor: item?.id === playId ? Colors.MainBlue50 : "",
                borderBottom:
                  item?.id === playId
                    ? `1px solid ${Colors.MainDark500}`
                    : `1px solid ${Colors.MainDark100}`,
              }}
            >
              <Flex
                align="center"
                justify="space-between"
                style={{ paddingRight: 8, width: "100%" }}
              >
                <Flex align="center" gap={16}>
                  {item?.id !== playId ? (
                    <PlayIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => startSequences(item?.id)}
                    />
                  ) : (
                    <StopIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => startSequences(item?.id)}
                    />
                  )}
                  <EditIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => editSequences(item?.id)}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: 700,
                    }}
                  >
                    {item.name}
                  </Text>
                </Flex>
                <DeleteBasket
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(item?.id)}
                />
              </Flex>
            </CardStyled>
          ))}
        {!data?.length && !isLoading && (
          <Flex
            vertical
            align="center"
            justify="center"
            style={{ marginTop: "30%" }}
          >
            <Title
              level={5}
              style={{
                fontWeight: 700,
                color: Colors.MainDark300,
              }}
            >
              {t("No sequences yet")}...
            </Title>
            <Title
              level={5}
              style={{
                color: Colors.MainDark300,
                marginTop: 0,
                textAlign: "center",
              }}
            >
              {t("You can create a new one by clicking the button below")}
            </Title>
            <Button
              type="link"
              icon={<AddPlus />}
              iconPosition="start"
              style={{
                color: Colors.MainBlue500,
                fontSize: 16,
                fontWeight: 700,
                padding: 0,
              }}
              onClick={() => setOpen(true)}
            >
              {t("sequence")}
            </Button>
          </Flex>
        )}
      </Col>
    </>
  );
};

const CardStyled = styled(Flex)`
  padding: 16px 0;
  background-color: white;
  //cursor: move;
  padding-right: 8px;
  transition: 0.4s linear;
  &:hover {
    background-color: ${Colors.MainBlue50};
  }
`;
