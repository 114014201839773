import { Col, Divider, Flex, GetProp, MenuProps } from "antd";
import { Colors } from "constants/colors";
import { AdminsListPage } from "pages/admins-list-page";
import { Dashboard } from "pages/dashboard";
import { MenuStyled } from "pages/my-profile";
import { UsersListPage } from "pages/users-list-page";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserInfo, logout, selectUser } from "store/userSlice";
import { Templates } from "pages/templates";
import { MyProjects } from "pages/my-projects";
import {
  getFavourites,
  getProjects,
  getTemplates,
  selectDashboard,
  updateFilters,
} from "store/dashboardSlice";
import { useEffect } from "react";
import { updateFavourites } from "store/projectSlice";
import { FavoriteTrue, PencilIcon } from "pages/dashboard/icons";
import { ButtonStyle } from "shared/components";
import { HISTORY_INDEX_KEY, HISTORY_KEY } from "pages/project/Flow";
import { Routes as Links } from "constants/routes";
import { Route, Routes } from "react-router-dom";
import { ROLES } from "shared/interfaces";
import { HomeMobile } from "./HomeMobile";
import useWindowWidth from "shared/hooks/useWindowWidth ";
import { useTranslation } from "react-i18next";

type MenuItem = GetProp<MenuProps, "items">[number];

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeRout = location.pathname.replace(/^\//, "");
  const { roles: roleUser } = useSelector(selectUser);
  const isSuperAdmin = roleUser.includes(ROLES.SUPER_ADMIN);
  const isAdmin = roleUser.includes(ROLES.ADMIN);
  const { dataFavourites } = useAppSelector(selectDashboard);
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  const logOut = () => {
    dispatch(logout());
    navigate("/");
    dispatch(getUserInfo()).finally(() => window.location.reload());
  };

  const handleMenuClick = ({ key }: { key: string }) => {
    navigate(`/${key}`);
    dispatch(updateFilters({ searchText: "" }));
  };

  useEffect(() => {
    dispatch(getFavourites());
  }, []);

  const handleEdit = (e: any, id: number) => {
    e.stopPropagation();
    if (id) {
      navigate(`/${Links.project}/edit/${id}`);
      localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
      localStorage.setItem(HISTORY_INDEX_KEY, "0");
    }
  };
  const handleFavourites = (e: any, id: number) => {
    e.stopPropagation();
    const data = dataFavourites?.find((item) => item?.project_id === id);
    if (data) {
      dispatch(updateFavourites({ favorite: 0, id: id }));
      setTimeout(() => {
        dispatch(getTemplates());
        dispatch(getProjects());
        dispatch(getFavourites());
      }, 1000);
    }
  };

  const items: MenuItem[] = [
    {
      key: Links.dashboard,
      label: t("My dashboard"),
      children: [
        { key: Links.base, label: t("Show all"), className: "child-menu_item" },
        {
          key: Links.templates,
          label: t("Templates"),
          className: "child-menu_item",
        },
        {
          key: Links.myProjects,
          label: t("My projects"),
          className: "child-menu_item",
        },
        {
          key: "favourites",
          label: t("Favourites"),
          children: [
            ...dataFavourites?.map((item) => ({
              key: `${Links.project}/${item?.project_id}`,
              icon: (
                <ButtonStyle
                  style={{
                    backgroundColor: Colors.MainBlue500,
                    width: 16,
                    height: 16,
                    borderRadius: 3,
                  }}
                  className="edit_btn"
                  onClick={(e) => handleEdit(e, item?.project_id)}
                >
                  <PencilIcon style={{ width: "13px", height: "13px" }} />
                </ButtonStyle>
              ),
              label: (
                <Flex justify="space-between">
                  {truncateText(item?.project_name, 19)}
                  <span onClick={(e) => handleFavourites(e, item?.project_id)}>
                    <FavoriteTrue style={{ width: "12px", height: "12px" }} />
                  </span>
                </Flex>
              ),
            })),
          ],
        },
      ],
    },
    ...(isSuperAdmin
      ? [
          {
            key: Links.manageAdmins,
            label: t("Manage Admins"),
          },
        ]
      : []),
    ...(isSuperAdmin || isAdmin
      ? [
          {
            key: Links.manageUsers,
            label: t("Manage Users"),
          },
        ]
      : []),
  ];

  if (windowWidth < 600) {
    return <HomeMobile />;
  }

  return (
    <Flex style={{ height: "100%" }}>
      <Flex style={{ marginTop: 32 }} vertical justify="space-between">
        <MenuStyled
          mode="inline"
          style={{ width: 235, borderInlineEnd: 0 }}
          defaultSelectedKeys={[activeRout]}
          defaultOpenKeys={["dashboard"]}
          items={items}
          onClick={handleMenuClick}
        />
        <Col
          style={{ padding: 32, fontSize: 16, position: "absolute", bottom: 0 }}
        >
          <Link to="/" onClick={logOut} style={{ color: Colors.MainDark500 }}>
            {t("Log out")}
          </Link>
        </Col>
      </Flex>
      <Divider type="vertical" style={{ height: "auto", margin: 0 }} />
      <Col style={{ padding: 32, width: "100%" }}>
        <Routes>
          {menuRoutes.map((route, key) => (
            <Route
              key={key}
              path={route.path}
              element={
                <PrivateRoute
                  roles={route.roles}
                  key={key}
                  children={route.component}
                />
              }
            />
          ))}
        </Routes>
      </Col>
    </Flex>
  );
};

export function truncateText(text: string, maxLength: number) {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.slice(0, maxLength) + "...";
}

export const menuRoutes = [
  {
    path: Links.base,
    component: <Dashboard />,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.USER],
  },
  {
    path: Links.templates,
    component: <Templates />,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.USER],
  },
  {
    path: `${Links.myProjects}`,
    component: <MyProjects />,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.USER],
  },
  {
    path: `${Links.manageAdmins}`,
    component: <AdminsListPage />,
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    path: `${Links.manageUsers}`,
    component: <UsersListPage />,
    roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
  },
  {
    path: "*",
    component: <Dashboard />,
  },
];

const PrivateRoute = ({ children, roles }: any) => {
  const userRoles = useAppSelector(selectUser)?.roles || [];
  const hasAccess = roles?.some((role: any) => userRoles?.includes(role));
  return hasAccess ? children : <Navigate to="/" />;
};
