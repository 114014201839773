import {
  Col,
  Flex,
  Input,
  Pagination,
  PaginationProps,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { CreateUser } from "./CreateUser";
import { UpdateUser } from "./UpdateUser";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getData,
  selectManageUser,
  updateFilters,
  updatePagination,
  updateSorter,
} from "store/manageUserSlice";
import { debounce } from "lodash";
import { Columns } from "./columns";
import { ButtonApprove } from "shared/components";
import { FilterValue } from "antd/es/table/interface";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export const UsersListPage = () => {
  const dispatch = useAppDispatch();
  //const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openUpdateDate, setOpenUpdateDate] = useState();
  const { data, fetchStatus, pagination } = useAppSelector(selectManageUser);
  const isLoading = fetchStatus === "fetching";
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getData());
  }, []);

  // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const showModal = () => {
    setOpen(true);
  };

  const handleChangeSearch = debounce((e: any) => {
    const value = e?.target?.value;
    dispatch(updateFilters({ searchText: value }));
    dispatch(getData());
  }, 1000);

  const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
    dispatch(
      updatePagination({
        ...pagination,
        currentPage: pageNumber,
      })
    );
    dispatch(getData());
  };

  const handleChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: any
    ) => {
      dispatch(
        updateSorter({
          sortBy: sorter?.columnKey,
          sortOrder: sorter?.order,
        })
      );
      dispatch(getData());
    },
    []
  );

  return (
    <Col>
      <CreateUser open={open} setOpen={setOpen} />
      <UpdateUser
        open={openUpdate}
        setOpen={setOpenUpdate}
        data={openUpdateDate}
      />
      <Flex justify="space-between" align="center">
        <Title level={3} style={{ margin: 0, fontWeight: 800 }}>
          {t("Manage Users")}
        </Title>
        <ButtonApprove
          text={t("Create user")}
          handle={showModal}
          style={{ marginTop: 0 }}
        />
      </Flex>
      <Flex style={{ margin: "24px 0" }} justify="space-between" align="center">
        <Input
          placeholder={t("Search by first name, last name, email")}
          prefix={<SearchOutlined />}
          style={{ width: 400, borderRadius: 50, height: 40 }}
          onChange={handleChangeSearch}
        />
        <Pagination
          align="end"
          defaultCurrent={pagination?.currentPage}
          total={pagination?.total}
          onChange={onChangePagination}
        />
      </Flex>
      <Table
        //rowSelection={rowSelection}
        columns={Columns()}
        dataSource={data}
        pagination={false}
        loading={isLoading}
        onRow={(data) => {
          return {
            onClick: (e) => {
              setOpenUpdateDate(data);
              setOpenUpdate(true);
            },
          };
        }}
        onChange={handleChange}
      />
    </Col>
  );
};
