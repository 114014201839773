import { Button } from "antd";
import { Colors } from "constants/colors";
import { t } from "i18next";
import styled, { CSSProperties } from "styled-components";

export const ButtonTransparent = ({
  text = t("Cancel"),
  handle,
  style,
  icon,
  iconPosition,
  width,
}: {
  text?: string;
  handle: (e?: any) => void;
  style?: CSSProperties;
  icon?: React.ReactNode;
  iconPosition?: "start" | "end";
  width?: string | number;
}) => {
  return (
    <Button
      key="back"
      onClick={handle}
      htmlType="submit"
      style={{
        borderRadius: 50,
        fontSize: 16,
        height: 40,
        marginTop: 24,
        border: `2px solid ${Colors.MainBlue500}`,
        color: Colors.MainBlue500,
        fontWeight: 700,
        width,
        ...style,
      }}
      icon={icon}
      iconPosition={iconPosition}
    >
      {text}
    </Button>
  );
};

export const ButtonApprove = ({
  text = t("Create"),
  handle,
  loading,
  style,
  width,
}: {
  text?: string;
  handle?: () => void;
  loading?: boolean;
  style?: CSSProperties;
  width?: string | number;
}) => {
  return (
    <Button
      type="primary"
      htmlType="submit"
      style={{
        borderRadius: 50,
        backgroundColor: Colors.MainBlue500,
        fontSize: 16,
        height: 40,
        marginTop: 24,
        fontWeight: 700,
        padding: "0 24px",
        width,
        ...style,
      }}
      onClick={handle}
      loading={loading}
    >
      {text}
    </Button>
  );
};

export const ButtonStyled = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 700;
  background-color: ${Colors.MainBlue500} !important;
  &:hover {
    background-color: ${Colors.MainBlue700} !important;
  }
`;
