import { Modal, Typography, Form, FormProps, Row, Col, Flex } from "antd";
import { Colors } from "constants/colors";
import { validationErrorMessages } from "constants/form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonApprove, ButtonTransparent } from "shared/components";
import { FormItem } from "shared/components/Form/FormItems";
import { useAppDispatch } from "store/hooks";
import { getData, updateUserManager } from "store/manageUserSlice";

const { Text, Title } = Typography;
const { required } = validationErrorMessages;

type FieldType = {
  email: string;
  first_name: string;
  last_name: string;
};

export const UpdateUser = ({
  open,
  setOpen,
  data,
}: {
  open: boolean;
  setOpen: (item: boolean) => void;
  data: any;
}) => {
  const [form] = Form.useForm<FieldType>();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { email, first_name, last_name } = data || {};
  const { t } = useTranslation();

  const handleCancel = () => {
    form.resetFields();
    setOpen(!open);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ email, first_name, last_name });
    }
  }, [data]);

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    setLoading(true);
    dispatch(updateUserManager({ data: values, id: data?.id }))
      .then(() => {
        dispatch(getData());
        handleCancel();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ fontWeight: 800 }}>
          {data?.name}
        </Title>
      }
      onCancel={handleCancel}
      width={500}
      footer={null}
    >
      <Form
        name="update_user"
        onFinish={onFinish}
        style={{ marginTop: 16 }}
        form={form}
      >
        <FormItem
          label="Email"
          placeholder="Email"
          formItemProps={{
            name: "email",
          }}
          disabled
        />
        <Text
          style={{
            color: Colors.MainDark200,
            fontSize: 12,
            display: "block",
            marginTop: -8,
            marginBottom: 16,
          }}
        >
          {t(
            "You can’t change the email. You can only delete it and create a new one"
          )}
        </Text>
        <Row gutter={16}>
          <Col span={12} style={{ padding: 0 }}>
            <FormItem
              label={t("First name")}
              placeholder={t("First name")}
              formItemProps={{
                name: "first_name",
                rules: [{ required: true, message: required }],
              }}
            />
          </Col>
          <Col span={12} style={{ padding: 0 }}>
            <FormItem
              label={t("Last name")}
              placeholder={t("Last name")}
              formItemProps={{
                name: "last_name",
                rules: [{ required: true, message: required }],
              }}
            />
          </Col>
        </Row>
        <FormItem
          label={t("Password")}
          placeholder={t("Password")}
          formItemProps={{
            name: "password",
            rules: [{ required: true, message: required }],
          }}
          type="password"
          colProps={{
            style: { marginBottom: "-16px" },
          }}
        />
        <Flex justify="space-between" gap={16}>
          <ButtonTransparent handle={handleCancel} width="100%" />
          <ButtonApprove
            text={t("Save changes")}
            loading={loading}
            width="100%"
          />
        </Flex>
      </Form>
    </Modal>
  );
};
