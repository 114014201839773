import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Device, IStore } from "shared/interfaces";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";

const initialState: any = {
  data: [],
  fetchStatus: "init",
  pagination: {
    currentPage: 1,
    total: 1,
    perPage: 100,
  },
  sorter: {},
  filters: {},
  fetchError: null,
  activeSequences: [],
};

export const deleteSequences = createAsyncThunk(
  "sequences/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      const req = await axios.delete(ENDPOINTS.sequenceById(String(id)));
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateSequences = createAsyncThunk(
  "sequences/updateUser",
  async (values: { id: number; data: Device }, { rejectWithValue }) => {
    const { id, data } = values;
    try {
      const req = await axios.put(ENDPOINTS.sequenceById(id), data);
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getData = createAsyncThunk(
  "sequences/Data",
  async (id: string | undefined, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { filters } = state.sequences;
    const queryParams = {
      search: filters?.searchText,
    };
    try {
      const res = await axios.get(ENDPOINTS.sequenceByProject(id ?? ""), {
        params: queryParams,
      });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const sequencesSlice = createSlice({
  name: "sequences",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
    playSequences: (state, action) => {
      state.activeSequences = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.fetchStatus = "fetching";
      })
      .addCase(getData.fulfilled, (state, { payload }) => {
        const { data } = payload || {};
        state.fetchStatus = "success";
        state.data = data;
      })
      .addCase(getData.rejected, (state, { error }) => {
        state.fetchStatus = "error";
        //@ts-ignore
        state.fetchError = error;
      });
  },
});

export const { updateFilters, playSequences } = sequencesSlice.actions;

export const selectSequences = (state: RootState) => state.sequences;

export default sequencesSlice.reducer;
