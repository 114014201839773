import React, { CSSProperties, PropsWithChildren } from "react";
import { Head } from "./Head";
import styled from "styled-components";
import { Flex } from "antd";

export type IStyledChildrenProps<P> = PropsWithChildren<P> & CSSProperties;

export const BaseContainer: React.FC<IStyledChildrenProps<any>> = ({
  children,
  ...props
}) => {
  return (
    <>
      <Head />
      <div style={{ height: "auto", minHeight: 550 }} {...props}>
        {children}
      </div>
    </>
  );
};

export const Container = styled(Flex)`
  max-width: 490px;
  margin: auto;
  height: calc(100vh - 64px);
  padding: 0 20px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
`;
