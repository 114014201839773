import { Typography } from "antd";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonStyled, Container } from "shared/components";
import { ENDPOINTS } from "shared/fethers";
import { useAppDispatch } from "store/hooks";
import { getUserInfo } from "store/userSlice";

const { Title, Paragraph } = Typography;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const id = query.get("id");
  const token = query.get("token");
  const expires = query.get("expires");
  const signature = query.get("signature");

  const onFinish = () => {
    axios
      .post(ENDPOINTS.verifyEmail(), { id, token, expires, signature })
      .then((item) => {
        localStorage.setItem("access_token", item?.data?.access_token);
        dispatch(getUserInfo());
        navigate("/");
      });

  };

  return (
    <Container>
      <Title
        level={1}
        color="#1D1D39"
        style={{ fontSize: 48, marginBottom: 0, textAlign: "center" }}
      >
        Congrats
      </Title>
      <Paragraph
        style={{ textAlign: "center", fontSize: 16, margin: "8px 0 24px" }}
      >
        Your email has been confirmed.
      </Paragraph>

      <ButtonStyled type="primary" htmlType="submit" onClick={onFinish}>
        Go to Home page
      </ButtonStyled>
    </Container>
  );
};
