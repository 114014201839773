import { Colors } from "constants/colors";
import { Col, Flex, Typography } from "antd";
import { ReactFlow, ReactFlowProvider } from "@xyflow/react";
import { useNavigate } from "react-router-dom";
import { FavoriteTrue, FavoriteFalse } from "./icons";
import { useAppDispatch } from "store/hooks";
import { updateFavourites } from "store/projectSlice";
import { ButtonStyle, customNodes } from "shared/components";
import styled from "styled-components";
import { Project } from "shared/interfaces";
import { useRef, useState } from "react";
import { getFavourites } from "store/dashboardSlice";
import { timeAgo } from "./ProjectCard";
import { Routes } from "constants/routes";

const { Title, Text } = Typography;

export const ProjectCardMobile = ({ data }: { data: Project }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reactFlowWrapper = useRef<any>(null);
  const { name, updated_at, template, id, is_favorite } = data || {};
  const [favoriteStatus, setFavoriteStatus] = useState(is_favorite);
  const { edges, nodes } = template || {};

  const handleFavorite = (event: any) => {
    event.stopPropagation();
    dispatch(updateFavourites({ favorite: +!favoriteStatus, id: id })).then(
      () => {
        setFavoriteStatus(!favoriteStatus);
        dispatch(getFavourites());
      }
    );
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  const handleOpen = (event: any) => {
    event.stopPropagation();
    navigate(`/${Routes.project}/${id}`);
  };

  return (
    <Flex style={{ marginTop: 16, width: "100%" }} justify="space-between">
      <Flex gap={16} style={{ width: "100%" }} onClick={handleOpen}>
        <CardImageStyled xs={10}>
          <div
            className="providerflow"
            style={{ height: "100%", width: "100%" }}
          >
            <ReactFlowProvider>
              <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={customNodes(nodes)}
                  fitView
                  maxZoom={1}
                  zoomOnScroll={false}
                />
              </div>
            </ReactFlowProvider>
          </div>
        </CardImageStyled>
        <Flex vertical justify="center">
          <Title
            style={{
              marginBottom: 4,
              fontSize: 12,
              fontWeight: 700,
              color: Colors.MainDark500,
            }}
          >
            {name}
          </Title>
          <Text style={{ fontSize: 10, color: Colors.MainDark300 }}>
            {timeAgo(updated_at)}
          </Text>
        </Flex>
      </Flex>

      <Flex onClick={handleClick} gap={8} align="center">
        <ButtonStyle
          className={!!favoriteStatus ? "" : "edit_btn"}
          onClick={handleFavorite}
        >
          {!!favoriteStatus ? <FavoriteTrue /> : <FavoriteFalse />}
        </ButtonStyle>
      </Flex>
    </Flex>
  );
};

const CardImageStyled = styled(Col)`
  border-radius: 8px;
  background-color: ${Colors.MainDark50};
  position: relative;
  padding: 0 !important;
  min-height: 80px;
  min-width: 105px;
  width: 100%;
`;
