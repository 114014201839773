import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import manageUserReducer from "./manageUserSlice";
import manageAdminReducer from "./manageAdminSlice";
import appliancesReducer from "./appliancesSlice";
import dashboardReducer from "./dashboardSlice";
import projectReducer from "./projectSlice";
import sequencesReducer from "./sequencesSlice"
import languageReducer from "./languageSlice"

const store = configureStore({
  reducer: {
    user: userReducer,
    manageUser: manageUserReducer,
    manageAdmins: manageAdminReducer,
    appliances: appliancesReducer,
    dashboard: dashboardReducer,
    project: projectReducer,
    sequences: sequencesReducer,
    language: languageReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export default store;
export type RootState = ReturnType<typeof store.getState>;
