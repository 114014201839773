import { DeleteOutlined } from "@ant-design/icons";
import { Flex, Modal, Typography } from "antd";
import { EmptyString } from "constants/strings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonApprove, ButtonTransparent } from "shared/components";
import { useAppDispatch } from "store/hooks";
import { deleteUserManager, getData } from "store/manageUserSlice";

interface DataType {
  key: React.Key;
  first_name: string;
  last_name: string;
  email: string;
}

export const Columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("First name"), // Use t function for localization
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
      render: (data: string) => (data ? data : EmptyString),
    },
    {
      title: t("Last name"),
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
      render: (data: string) => (data ? data : EmptyString),
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (data: string) => (data ? data : EmptyString),
    },
    {
      title: t("Actions"),
      dataIndex: "actions",
      key: "actions",
      width: 50,
      render: (_: any, record: any) => <DeleteModal data={record} />,
    },
  ];
};

const { Title, Text } = Typography;

const DeleteModal = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const onFinish = async () => {
    try {
      await dispatch(deleteUserManager(data?.id));
      dispatch(getData());
      handleClickModal();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickModal = () => {
    setOpen(!open);
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{ textAlign: "center", cursor: "pointer" }}
    >
      <DeleteOutlined style={{ fontSize: 18 }} onClick={handleClickModal} />
      <Modal
        open={open}
        title={
          <Title level={3} style={{ marginBottom: 8, fontWeight: 800 }}>
            Delete {data.name}
          </Title>
        }
        onCancel={handleClickModal}
        width={422}
        footer={null}
      >
        <Text style={{ fontSize: 16, lineHeight: 1.5 }}>
          Are you sure you want to delete user from the list?
        </Text>
        <Flex justify="space-between" gap={16}>
          <ButtonTransparent handle={handleClickModal} width="100%" />
          <ButtonApprove text="Yes, delete it" handle={onFinish} width="100%" />
        </Flex>
      </Modal>
    </div>
  );
};
