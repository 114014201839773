import { Col, Flex, Row, Layout } from "antd";
import { Logo } from "shared/icons";
import { UserNme } from "./UserName";
import { Languages } from "./Languages";
import { Routes } from "constants/routes";
import { Link } from "react-router-dom";
import useWindowWidth from "shared/hooks/useWindowWidth ";
import { useAppSelector } from "store/hooks";
import { selectUser } from "store/userSlice";

const { Header } = Layout;

export const Head = () => {
  const { isAuthorized } = useAppSelector(selectUser);

  const windowWidth = useWindowWidth();
  const isSmall = windowWidth < 600;
  return (
    <Header
      style={{
        backgroundColor: "#E2F0FF",
        padding: isSmall ? "0 16px" : "0 32px",
        position: isSmall ? "fixed" : "relative",
        width: "100%",
        zIndex: 1000,
      }}
    >
      <Row justify="space-between">
        <Flex style={{ alignItems: "center" }}>
          <Link style={{ display: "flex" }} to={`/${Routes.base}`}>
            <Logo />
          </Link>
        </Flex>
        <Col md={14} lg={8}>
          <Row justify={isAuthorized ? "space-between" : "end"} gutter={16}>
            <Col>
              <Languages />
            </Col>
            {isAuthorized && (
              <Col>
                <UserNme />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Header>
  );
};
