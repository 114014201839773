export const Routes = {
  base: "",
  login: "login",
  signUp: "sign-up",
  verifyEmail: "verify-email",
  forgotPassword: "forgot-password",
  resetPassword: "password-reset",
  myProfilePage: "my-profile",
  dashboard: "dashboard",
  manageAdmins: "manage-admins",
  manageUsers: "manage-users",
  project: "project",
  newProject: "new-project",
  allProjects: "all-projects",
  myProjects: "my-projects",
  templates: "templates",
};
