import { Col, Form, FormProps, Row, Typography } from "antd";
import { Routes } from "constants/routes";
import { validationErrorMessages } from "constants/form";
import { FormItem } from "shared/components/Form/FormItems";
import { useAppDispatch } from "store/hooks";
import { getUserInfo, login, selectUser } from "store/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "constants/colors";
import { useSelector } from "react-redux";
import { ButtonStyled, Container, ErrorText, TitleH1 } from "shared/components";
import { Google, Facebook, Linkedin } from "shared/icons";
import { ENDPOINTS } from "shared/fethers";
import { AuthType } from "shared/interfaces";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const { required, email } = validationErrorMessages;

type FieldType = {
  email?: string;
  password?: string;
};

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error, status } = useSelector(selectUser);
  const isLoading = status === "fetching";
  const { t } = useTranslation();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    const { email = "", password = "" } = values || {};

    await dispatch(login({ email, password })).then(({ type }) => {
      if (type !== "user/Login/rejected") {
        dispatch(getUserInfo());
        navigate("/");
      }
    });
  };

  return (
    <Container>
      <TitleH1 text={t("Login")} />
      <Form
        name="login"
        onFinish={onFinish}
        style={{ width: "100%", marginTop: 24 }}
      >
        <FormItem
          label={t("Email")}
          placeholder={t("Email")}
          formItemProps={{
            name: "email",
            rules: [
              {
                min: 1,
                max: 240,
                type: "email",
                message: email,
              },
              { required: true, message: required },
            ],
          }}
        />
        <FormItem
          label={t("Password")}
          placeholder={t("Password")}
          formItemProps={{
            name: "password",
            rules: [{ required: true, message: required }],
          }}
          type="password"
        />

        <Form.Item>
          <ButtonStyled type="primary" htmlType="submit" loading={isLoading}>
            {t("Log in")}
          </ButtonStyled>
        </Form.Item>
        <ErrorText text={error ?? ""} />

        <Form.Item>
          <Link
            style={{ fontSize: 16, fontWeight: 700, color: Colors.MainBlue500 }}
            to={`/${Routes.forgotPassword}`}
          >
            {t("Forgot your password?")}
          </Link>
        </Form.Item>

        <Row style={{ marginBottom: 20 }} justify="center" gutter={24}>
          <Col style={{ cursor: "pointer" }}>
            <Link to={ENDPOINTS.socialNetworks(AuthType.GOOGLE?.toLowerCase())}>
              <Google />
            </Link>
          </Col>
          <Col style={{ cursor: "pointer" }}>
            <Link
              to={ENDPOINTS.socialNetworks(AuthType.LINKEDIN?.toLowerCase())}
            >
              <Linkedin />
            </Link>
          </Col>
          <Col style={{ cursor: "pointer" }}>
            <Link
              to={ENDPOINTS.socialNetworks(AuthType.FACEBOOK?.toLowerCase())}
            >
              <Facebook />
            </Link>
          </Col>
        </Row>

        <Row justify="space-between">
          <Text>{t("Don’t have an account?")}</Text>
          <Link
            style={{
              fontSize: 16,
              fontWeight: 700,
              color: Colors.MainBlue500,
            }}
            to={`/${Routes.signUp}`}
          >
            {t("Sign up")}
          </Link>
        </Row>
      </Form>
    </Container>
  );
};
