const baseUrl = process.env.REACT_APP_API_URL;

const authUrl = process.env.REACT_APP_AUTH;

const getApiUrlBase = () => `${baseUrl}`;
const getApiAuthUrl = () => `${authUrl}`;

export const ENDPOINTS = {
  sanctum: () => `${getApiUrlBase()}sanctum/csrf-cookie`,

  user: () => `${getApiUrlBase()}api/me`,
  token: () => `${getApiUrlBase()}api/login`,
  register: () => `${getApiUrlBase()}register`,
  logoutUser: () => `${getApiUrlBase()}logout`,
  forgotPassword: () => `${getApiUrlBase()}forgot-password`,
  resetPassword: () => `${getApiUrlBase()}reset-password`,
  verifyEmail: () => `${getApiUrlBase()}verify-email`,

  userInfo: () => `${getApiUrlBase()}api/user-info`,
  userPassword: () => `${getApiUrlBase()}api/user-password`,

  adminById: (id: string) => `${getApiUrlBase()}api/admins/${id}`,
  admins: () => `${getApiUrlBase()}api/admins`,

  userById: (id: string) => `${getApiUrlBase()}api/users/${id}`,
  users: () => `${getApiUrlBase()}api/users`,

  fields: () => `${getApiUrlBase()}api/fields`,

  socialNetworks: (type: string) => `${getApiAuthUrl()}${type}`,

  projects: () => `${getApiUrlBase()}api/projects`,
  projectById: (id: number | string) => `${getApiUrlBase()}api/projects/${id}`,
  projectByToken: (token: string) =>
    `${getApiUrlBase()}api/projects/share/${token}`,

  devices: () => `${getApiUrlBase()}api/devices`,
  updateDevice: (id: string) => `${getApiUrlBase()}api/devices-update/${id}`,
  deviceById: (id: number | string) => `${getApiUrlBase()}api/devices/${id}`,

  sequences: () => `${getApiUrlBase()}api/sequences`,
  sequenceByProject: (id: string) =>
    `${getApiUrlBase()}api/sequences/project/${id}`,
  sequenceById: (id: number | string) =>
    `${getApiUrlBase()}api/sequences/${id}`,

  projectFavorites: () => `${getApiUrlBase()}api/project-favorites`,
  projectFavoritesById: (id: number | string) =>
    `${getApiUrlBase()}api/project-favorites/${id}`,

  generateCopyLink: (id: string) =>
    `${getApiUrlBase()}api/projects/${id}/generate-copy-link`,

  authContactForm: () => `${getApiUrlBase()}api/auth-contact-form`,
  contactForm: () => `${getApiUrlBase()}api/contact-form`,
};
