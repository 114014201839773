export const DeleteBasket = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...rest}
    >
      <path
        d="M1.92189 2.5491H3.1768H6.00033H8.82386H10.0788L9.76503 11.3334H2.23562L1.92189 2.5491Z"
        fill="white"
      />
      <path
        d="M1.92189 2.5491L2.23562 11.3334H9.76503L10.0788 2.5491M1.92189 2.5491H0.666992M1.92189 2.5491H3.1768M10.0788 2.5491H11.3337H8.82386M10.0788 2.5491H8.82386M3.1768 2.5491H6.00033H8.82386M3.1768 2.5491V0.666748H8.82386V2.5491"
        stroke="#1D1D39"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
