export const Facebook = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="40" height="40" rx="20" fill="#1877F2" />
      <path
        d="M17.1008 21.1628V29.845L20.8217 30V21.1628H23.7674L24.3876 17.5969H20.9767V15.2713C20.9767 14.3411 21.4419 13.4109 22.9922 13.4109H24.5426V10.3101C24.5426 10.3101 23.1473 10 21.7519 10C18.9612 10 17.1008 11.7054 17.1008 14.8062V17.5969H14V21.1628H17.1008Z"
        fill="white"
      />
    </svg>
  );
};
