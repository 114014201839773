import { Form, FormProps, Typography } from "antd";
import { Routes } from "constants/routes";
import { validationErrorMessages } from "constants/form";
import { FormItem } from "shared/components/Form/FormItems";
import { useState } from "react";
import { Link } from "react-router-dom";
import { forgotPassword } from "store/userSlice";
import { useAppDispatch } from "store/hooks";
import { Colors } from "constants/colors";
import { ButtonStyled, Container, ErrorText, TitleH1 } from "shared/components";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;
const { required, email } = validationErrorMessages;

type FieldType = {
  email?: string;
};

export const ForgotPasswordPage = () => {
  const [isForgot, setForgotStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    setForgotStatus(false);
    setLoading(true);
    dispatch(forgotPassword(values?.email ?? ""))
      .unwrap()
      .then(() => setForgotStatus(true))
      .catch((e) => setError(e?.message))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <TitleH1 text={t("Reset password")} />
      <Form
        name="reset_assword"
        onFinish={onFinish}
        style={{ width: "100%", marginTop: 24 }}
      >
        <Paragraph style={{ fontSize: 16 }}>
          {t(
            "Type in your email and we'll send you a link to reset your password"
          )}
        </Paragraph>
        {isForgot && (
          <Paragraph style={{ fontSize: 16, color: Colors.MainGreen500 }}>
            {t("We have emailed your password reset link.")}
          </Paragraph>
        )}
        <FormItem
          label="Email"
          placeholder="Email"
          formItemProps={{
            name: "email",
            rules: [
              {
                min: 1,
                max: 240,
                type: "email",
                message: email,
              },
              { required: true, message: required },
            ],
          }}
        />
        <Form.Item>
          <ButtonStyled
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            {t("Send message")}
          </ButtonStyled>
        </Form.Item>

        <ErrorText text={error ?? ""} />

        <Link
          style={{ fontSize: 16, fontWeight: 700, color: Colors.MainBlue500 }}
          to={`/${Routes.login}`}
        >
          {t("Back to log in")}
        </Link>
      </Form>
    </Container>
  );
};
