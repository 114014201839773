export const IconOpenClose = ({...rest}) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...rest}
      >
        <circle cx="12" cy="12" r="10" fill="#4EA5FF" />
        <path
          d="M5 12H15M15 12L12 9M15 12L12 15"
          stroke="white"
          strokeWidth="0.6875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 7V17"
          stroke="white"
          strokeWidth="0.6875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };