export const Computer = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="104"
      viewBox="0 0 105 104"
      fill="none"
      {...rest}
    >
      <path
        d="M9.5 24C9.5 22.067 11.067 20.5 13 20.5L93 20.5C94.933 20.5 96.5 22.067 96.5 24L96.5 74C96.5 75.933 94.933 77.5 93 77.5L53 77.5H13C11.067 77.5 9.5 75.933 9.5 74L9.5 24Z"
        stroke="#1D1D39"
      />
      <path
        d="M53 78V85M53 85H35M53 85H71"
        stroke="#1D1D39"
        strokeLinecap="round"
      />
      <rect
        x="14.5"
        y="25.5"
        width="77"
        height="47"
        rx="2.5"
        fill="white"
        stroke="#1D1D39"
      />
      <circle cx="21.5" cy="28.5" r="0.5" fill="#1D1D39" />
      <circle cx="19.5" cy="28.5" r="0.5" fill="#1D1D39" />
      <circle cx="17.5" cy="28.5" r="0.5" fill="#1D1D39" />
    </svg>
  );
};
