import { Col, Typography } from "antd";
import { Colors } from "constants/colors";
import { CSSProperties } from "react";

const { Title, Text } = Typography;

export const TitleH1 = ({ text }: { text: string }) => {
  return (
    <Title
      level={1}
      color={Colors.MainDark500}
      style={{ fontSize: 48, marginBottom: 0, fontWeight: 800 }}
    >
      {text}
    </Title>
  );
};

export const ErrorText = ({
  text,
  style,
}: {
  text: string;
  style?: CSSProperties;
}) => {
  if (!text) {
    return null;
  }
  return (
    <Col style={{ marginTop: "-16px", marginBottom: 16, ...style }}>
      <Text type="danger">{text}</Text>
    </Col>
  );
};
