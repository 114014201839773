import { Col, Flex, Form, Modal, Row, Typography } from "antd";
import { Colors } from "constants/colors";
import { ButtonApprove, ButtonTransparent, FormItem } from "shared/components";
import { ListSettings } from "./ListSettings";
import { ListChoosed } from "./ListChoosed";
import { createContext, useEffect, useState } from "react";
import { DevicesAndConditions } from "./DevicesAndConditions";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { validationErrorMessages } from "constants/form";
import { useParams } from "react-router-dom";
import { getData } from "store/sequencesSlice";

const { Title } = Typography;

export const SequenceContext = createContext<any>(null);
const { required } = validationErrorMessages;

export const SequenceModal = ({
  open,
  setOpen,
  data,
}: {
  open: boolean;
  setOpen: (item: boolean) => void;
  data: any;
}) => {
  const handleCancel = () => {
    setOpen(false);
    setSequence([]);
    form.resetFields();
  };
  const { id } = useParams();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeCondition, setActiveCondition] = useState<number>();
  const [sequence, setSequence] = useState([]);

  useEffect(() => {
    if (data) {
      setSequence(data?.parameters);
      form.setFieldValue("name", data?.name);
    }
  }, [data]);

  const onFinish = async (values: any) => {
    setLoading(true);
    setError(null);
    await axios
      .post(ENDPOINTS.sequences(), {
        name: values?.name,
        parameters: sequence,
        project_id: id,
      })
      .then(() => {
        getData(id);
        handleCancel();
      })
      .catch((e) => setError(e?.response?.data?.message))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ fontWeight: 800, marginBottom: 24 }}>
          Sequence edit panel
        </Title>
      }
      onCancel={handleCancel}
      footer={null}
      width={"100%"}
    >
      <Form name="add_sequence" onFinish={onFinish} form={form}>
        <Col span={6}>
          <FormItem
            placeholder="Sequence name"
            formItemProps={{
              name: "name",
              rules: [{ required: true, message: required }],
            }}
            inputProps={{
              maxLength: 30,
            }}
          />
        </Col>
        <SequenceContext.Provider value={{ sequence, setSequence }}>
          <Row>
            <Col span={4}>
              <DevicesAndConditions />
            </Col>
            <Col span={12}>
              <ListChoosed
                activeCard={activeCondition}
                setActiveCard={setActiveCondition}
              />
            </Col>
            <Col span={8}>
              <ListSettings activeCard={activeCondition} />
            </Col>
          </Row>
        </SequenceContext.Provider>
        <Flex justify="end">
          <Col span={8}>
            <Flex justify="space-between" gap={16}>
              <ButtonTransparent handle={handleCancel} width={"100%"} />
              <ButtonApprove
                text="Save changes"
                loading={loading}
                width={"100%"}
              />
            </Flex>
          </Col>
        </Flex>
      </Form>
    </Modal>
  );
};

export const EmptyData = () => (
  <Flex vertical align="center" style={{ height: "100%" }} justify="center">
    <Title
      level={5}
      style={{
        fontWeight: 700,
        color: Colors.MainDark300,
      }}
    >
      No devices and conditions yet...
    </Title>
    <Title level={5} style={{ color: Colors.MainDark300, marginTop: 0 }}>
      You will see the list after putting device or condition card this panel
    </Title>
  </Flex>
);
