import { Empty, Flex, Input, Row, Spin, Typography } from "antd";
import { ChangeEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getProjects,
  selectDashboard,
  updateSorter,
  updateFilters,
} from "store/dashboardSlice";
import { useAppSelector } from "store/hooks";
import { AppDispatch } from "store/store";
import "@xyflow/react/dist/style.css";

import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { Project } from "shared/interfaces";
import { SorterText } from "pages/dashboard";
import { ProjectCard } from "pages/dashboard/ProjectCard";
import { ButtonApprove } from "shared/components";
import { useNavigate } from "react-router-dom";
import { createProject } from "store/projectSlice";
import { Routes } from "constants/routes";
import { HISTORY_INDEX_KEY, HISTORY_KEY } from "pages/project/Flow";
import { ProjectCardMobile } from "pages/dashboard/ProjectCardMobile";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export const MyProjects = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  const handleChangeSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFilters({ searchText: e.target.value }));
    dispatch(getProjects());
  }, 1000);

  return (
    <>
      <Flex justify="space-between">
        <Title level={3} style={{ fontWeight: 800, margin: 0 }}>
          {t("My projects")}
        </Title>
        <Input
          placeholder={t("Search by project name")}
          prefix={<SearchOutlined />}
          style={{ width: 280, borderRadius: 50, height: 40 }}
          onChange={handleChangeSearch}
          allowClear
        />
      </Flex>
      <MyProjectsBlock title={t("Projects")} />
    </>
  );
};

interface IProjectsBlock {
  title: string;
}

export const MyProjectsBlock = ({ title }: IProjectsBlock) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dataProjects, statusProjects, sorterProjects } =
    useAppSelector(selectDashboard);
  const isLoading = statusProjects === "fetching" || statusProjects === "init";

  const createNew = () => {
    dispatch(createProject({})).then((item: any) => {
      const { id } = item?.payload?.data || {};
      navigate(`/${Routes.project}/edit/${id}`);
      localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
      localStorage.setItem(HISTORY_INDEX_KEY, "0");
    });
  };
  const sortFn = () => {
    dispatch(updateSorter(0));
    dispatch(getProjects());
  };

  return (
    <>
      <Flex justify="space-between" align="center" style={{ marginTop: 24 }}>
        <Title level={5} style={{ fontWeight: 800 }}>
          {title}
        </Title>
        <Flex align="center">
          <SorterText type={sorterProjects} onClick={sortFn} />
          <ButtonApprove
            style={{ marginTop: 0, marginLeft: 16 }}
            text={t("Start a project")}
            handle={createNew}
          />
        </Flex>
      </Flex>
      <Row
        style={{
          minHeight: 275,
        }}
        gutter={16}
      >
        {isLoading && (
          <Spin
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
        {!isLoading &&
          dataProjects?.map((item: Project, index: number) => (
            <ProjectCard key={index} data={item} />
          ))}
        {dataProjects?.length === 0 && !isLoading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("No projects")}
            style={{ margin: "auto" }}
          />
        )}
      </Row>
    </>
  );
};

export const MyProjectsBlockMobile = ({ title }: IProjectsBlock) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { dataProjects, statusProjects, sorterProjects } =
    useAppSelector(selectDashboard);
  const isLoading = statusProjects === "fetching" || statusProjects === "init";

  const sortFn = () => {
    dispatch(updateSorter(0));
    dispatch(getProjects());
  };
  return (
    <>
      <Flex justify="space-between" align="center" style={{ marginTop: 24 }}>
        <Title level={5} style={{ fontWeight: 800 }}>
          {title}
        </Title>
        <SorterText type={sorterProjects} onClick={sortFn} />
      </Flex>
      <Row
        style={{
          margin: 0,
        }}
        gutter={16}
      >
        {isLoading && (
          <Spin
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
        {!isLoading &&
          dataProjects?.map((item: Project, index: number) => (
            <ProjectCardMobile key={index} data={item} />
          ))}
        {dataProjects?.length === 0 && !isLoading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("No projects")}
            style={{ margin: "auto" }}
          />
        )}
      </Row>
    </>
  );
};
