import { Handle } from "@xyflow/react";
import Pneumatic from "./connections/Pneumatic.svg";
import Liquid from "./connections/Liquid.svg";
import Sensor from "./connections/Sensor.svg";
import Valve from "./connections/Valve.svg";
import Circle from "./connections/Circle.svg";
import { Col, Flex, Form, Popover } from "antd";
import { INode, INodesNode } from "shared/interfaces";
import { Button, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { Colors } from "constants/colors";
import { t } from "i18next";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { openNotification } from "../Notification";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useAppSelector } from "store/hooks";
import { getProject, selectProject } from "store/projectSlice";
import { cloneDeep, set } from "lodash";
import { useParams } from "react-router-dom";

const DEFAULT_HANDLE_STYLE = {
  //width: 5,
  //height: 4,
  border: 0,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundColor: "transparent",
};

interface ICustomNodeProps extends INodesNode {
  width?: number;
  height?: number;
  info?: any;
  data?: {
    label: string;
  };
  name?: string;
  id: string;
}

const baseUrl = process.env.REACT_APP_API_URL_IMAGE;

export const CustomNodeHandle = ({
  data,
  props,
}: {
  data: ICustomNodeProps;
  props?: any;
}) => {
  const { id: idProject } = useParams();
  const {
    connectors,
    image,
    width,
    height,
    name,
    data: dataProps,
  } = data || {};
  const { id, data: info } = props || {};
  const [inputValue, setInputValue] = useState(info?.label ?? dataProps?.label);
  const dispatch = useDispatch<AppDispatch>();
  const { temporaryProject } = useAppSelector(selectProject);

  const handleSave = async () => {
    const updatedProject = cloneDeep(temporaryProject);
    const nodeIndex = updatedProject.template.nodes.findIndex(
      (node: any) => node?.id === id
    );
    if (nodeIndex !== -1) {
      set(
        updatedProject,
        `template.nodes[${nodeIndex}].data.label`,
        inputValue
      );
    }

    await axios
      .put(ENDPOINTS.projectById(idProject || ""), {
        template: updatedProject.template,
      })
      .then(() => {
        openNotification("success");
        setTimeout(() => dispatch(getProject(idProject || "")), 1000);
      })
      .catch(() => openNotification("error"));
  };

  const popoverContent = (
    <Flex gap={8}>
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter some text"
        style={{ marginBottom: "8px" }}
      />
      <Button
        type="link"
        style={{
          color: Colors.MainBlue500,
          fontSize: 16,
          fontWeight: 700,
          padding: 0,
        }}
        onClick={handleSave}
      >
        {t("Save")}
      </Button>
    </Flex>
  );

  return (
    <div>
      {(info?.label || dataProps?.label) && (
        <Popover content={popoverContent} trigger="click">
          <div
            style={{
              position: "absolute",
              fontSize: 7,
              top: "-4%",
              left: "100%",
              transform: "translateY(-50%)",
              marginLeft: "5px",
              whiteSpace: "nowrap",
              background: "white",
              cursor: "pointer",
            }}
          >
            {info?.label ?? dataProps?.label}
          </div>
        </Popover>
      )}
      <img
        src={`${baseUrl}` + image}
        alt=""
        width={width}
        height={height}
        style={{
          maxWidth: width ? width : 50,
          maxHeight: height ? height : 50,
        }}
      />
      {connectors?.map(
        (
          { top, left, position, type, connection_type_id, id, connection },
          index: number
        ) => {
          const direction = type === "source";
          const rotations = {
            top: direction ? "270deg" : "90deg",
            right: direction ? "0deg" : "180deg",
            bottom: direction ? "90deg" : "270deg",
            left: direction ? "180deg" : "0deg",
          };
          const isCustomEdge = connection === "custom";

          const rotate =
            rotations[position as keyof typeof rotations] || "0deg";
          const typeFlow = isCustomEdge
            ? Circle
            : connection_type_id === 1
            ? Pneumatic
            : connection_type_id === 2
            ? Liquid
            : connection_type_id === 3
            ? Sensor
            : Valve;

          return (
            <Handle
              type={type === "source" ? "target" : "source"}
              position={position as any}
              key={index}
              id={String(id)}
              //isConnectable={connections.length < props.connectionCount}
              style={{
                ...(!isCustomEdge && DEFAULT_HANDLE_STYLE),
                backgroundImage: `url(${typeFlow})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                top: `${top}%`,
                left: `${left}%`,
                transform: `translate(-50%, -50%) rotate(${rotate})`,
              }}
            />
          );
        }
      )}
    </div>
  );
};

export const customNodes = (nodes: any) =>
  nodes?.length
    ? nodes?.reduce((acc: any, item: INode) => {
        if (item?.type && item?.node) {
          acc[item?.type] = (props: any) => (
            <CustomNodeHandle
              data={{
                ...item?.node,
                width: item?.width,
                height: item?.height,
                ...item,
              }}
              props={props}
            />
          );
        }
        return acc;
      }, {})
    : [];

export const CustomDeviceImage = (props: any) => {
  const { paramsConnect, image, form, width, height } = props;

  const widthImage = Form.useWatch("width", form);
  const heightImage = Form.useWatch("height", form);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imgRef = useRef(null);

  useEffect(() => {
    if (!width) {
      form.setFieldValue("width", dimensions.width);
    }
    if (!height) {
      form.setFieldValue("height", dimensions.height);
    }
  }, [dimensions]);

  return (
    <div>
      <Col
        style={{
          maxWidth: 345,
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!!paramsConnect.length &&
          paramsConnect.map((item: any, index: number) => {
            const { position, type, connection_type_id } = item;
            const direction = type === "source";

            const rotations = {
              top: direction ? "270deg" : "90deg",
              right: direction ? "0deg" : "180deg",
              bottom: direction ? "90deg" : "270deg",
              left: direction ? "180deg" : "0deg",
            };

            const rotate =
              rotations[position as keyof typeof rotations] || "0deg";

            const typeFlow =
              connection_type_id === 1
                ? Pneumatic
                : connection_type_id === 2
                ? Liquid
                : connection_type_id === 3
                ? Sensor
                : Valve;
            return (
              <Flex
                style={{
                  position: "absolute",
                  top: item?.top,
                  left: item?.left,
                }}
                key={index}
              >
                <img
                  src={typeFlow}
                  alt=""
                  key={index}
                  style={{
                    display: "block",
                    margin: "0 auto",
                    transform: `rotate(${rotate})`,
                    height: 22,
                    position: "absolute",
                    top: item?.top,
                    left: item?.left,
                    marginLeft: "-10px",
                    marginTop: "-8px",
                  }}
                />
              </Flex>
            );
          })}
        <img
          ref={imgRef}
          src={image}
          alt=""
          style={{
            maxWidth: 305,
          }}
          onLoad={() => {
            setDimensions({
              width: (imgRef.current as any)?.offsetWidth,
              height: (imgRef.current as any)?.offsetHeight,
            });
          }}
          width={widthImage ? +widthImage : +width}
          height={heightImage ? +heightImage : +height}
        />
      </Col>
    </div>
  );
};
