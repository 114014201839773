import { Button, Flex, Input, Typography, Upload } from "antd";
import { Colors } from "constants/colors";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ErrorText } from "shared/components";
import { getTemplates, getProjects, updateFilters } from "store/dashboardSlice";
import { AppDispatch } from "store/store";
import { useNavigate } from "react-router-dom";
import { Routes } from "constants/routes";
import "@xyflow/react/dist/style.css";
import { DownloadIcon, RectangleIcon } from "./icons";
//import { LastProjectCard } from "./ProjectCard";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { createProject } from "store/projectSlice";
import { Project, ROLES } from "shared/interfaces";
import styled from "styled-components";
import { TemplatesBlock } from "pages/templates";
import { MyProjectsBlock } from "pages/my-projects";
import { HISTORY_INDEX_KEY, HISTORY_KEY } from "pages/project/Flow";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectUser } from "store/userSlice";

const { Title } = Typography;

export const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [erorrFile, setErorrFile] = useState("");
  const { t } = useTranslation();
  const { roles } = useAppSelector(selectUser);
  const isUser = roles.includes(ROLES.USER);

  useEffect(() => {
    dispatch(getTemplates());
    dispatch(getProjects());
  }, []);

  const handleChangeSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFilters({ searchText: e.target.value }));
    dispatch(getProjects());
    dispatch(getTemplates());
  }, 1000);

  const handleFileChange = ({ file }: any) => {
    setErorrFile("");
    if (file.type !== "application/json") {
      setErorrFile(t("Please, select a JSON file"));
      return;
    }
    const reader = new FileReader();
    reader.onload = (event: any) => {
      try {
        const jsonObject = JSON.parse(event.target.result);
        handleUpload(jsonObject);
      } catch (error) {
        console.log(error);
      }
    };
    reader.readAsText(file);
  };
  const handleUpload = async (file: Project) => {
    if (!file) return;
    let objCreate = {
      name: file?.name,
      template: file?.template,
      state_template: isUser ? 0 : file?.state_template,
    };
    dispatch(createProject(objCreate)).then((item: any) => {
      const { id } = item?.payload?.data || {};
      if (id) {
        navigate(`/${Routes.project}/edit/${id}`);
        localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
        localStorage.setItem(HISTORY_INDEX_KEY, "0");
      }
    });
  };

  return (
    <>
      <Flex justify="space-between">
        <Title
          level={3}
          style={{ fontWeight: 800, margin: 0, whiteSpace: "nowrap" }}
        >
          {t("My dashboard")}
        </Title>
        <Flex justify="flex-end">
          <UploadProjectStyle
            style={{ margin: 0, width: 300 }}
            accept=".json"
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <Button
              type="link"
              style={{
                fontSize: 16,
                fontWeight: 700,
                padding: 0,
                color: Colors.MainBlue500,
              }}
            >
              <DownloadIcon />
              {t("Import JSON file")}
            </Button>
            <ErrorText style={{ margin: 0 }} text={erorrFile} />
          </UploadProjectStyle>
          <Input
            placeholder={t("Search by project name")}
            prefix={<SearchOutlined />}
            style={{ width: 280, borderRadius: 50, height: 40 }}
            onChange={handleChangeSearch}
            allowClear
          />
        </Flex>
      </Flex>

      {/* <Flex>
        <LastProjectCard title="Project name" description="Edited 2 days ago" />
      </Flex> */}
      <TemplatesBlock title={t("Templates")} />
      <MyProjectsBlock title={t("My projects")} />
    </>
  );
};

export const SorterText = ({
  type,
  onClick,
  title,
}: {
  type: string;
  onClick: () => void;
  title?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {title && (
        <Title style={{ fontSize: 16, color: Colors.MainDark300, margin: 0 }}>
          {t("Sort by")}: &nbsp;
        </Title>
      )}
      <Button
        onClick={onClick}
        type="link"
        style={{
          fontSize: 16,
          color: Colors.MainDark500,
          fontWeight: 600,
          padding: 0,
        }}
      >
        {t("From")} {type === "asc" ? t("A to Z") : t("Z to A")}
        <RectangleIcon
          style={{
            transform: `rotate(${type === "asc" ? 0 : 180}deg)`,
          }}
        />
      </Button>
    </>
  );
};

const UploadProjectStyle = styled(Upload)`
  margin-right: 16px;
  .ant-upload-select {
    border: 0px !important;
    display: flex;
    align-items: center;
  }
`;
