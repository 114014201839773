import { Button, Col, Form, FormProps, Row, Typography } from "antd";
import { Routes } from "constants/routes";
import { validationErrorMessages } from "constants/form";
import { useAppDispatch } from "store/hooks";
import { register } from "store/userSlice";
import { TRegister } from "shared/fethers/authClient/auth/interfaces";
import { Link } from "react-router-dom";
import { Colors } from "constants/colors";
import { useState } from "react";
import {
  ButtonStyled,
  Container,
  ErrorText,
  FormItem,
  TitleH1,
} from "shared/components";
import { Google, Facebook, Linkedin } from "shared/icons";
import { AuthType } from "shared/interfaces";
import { ENDPOINTS } from "shared/fethers";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
const { required, email } = validationErrorMessages;

export const SignUpPage = () => {
  const dispatch = useAppDispatch();
  const [statusRegister, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const onFinish: FormProps<TRegister>["onFinish"] = async (values) => {
    setStatus(false);
    setLoading(true);
    setError(null);
    dispatch(register({ values }))
      .unwrap()
      .then(() => setStatus(true))
      .catch((e) => setError(e?.message))
      .finally(() => {
        setLoading(false);
      });
  };

  if (statusRegister) {
    return (
      <Container>
        <Title level={5} style={{ textAlign: "center" }}>
          {t(
            `Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.`
          )}
        </Title>
        <Row justify="space-between" align="middle" style={{ marginTop: 24 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("Resend verification email")}
          </Button>
          <Link style={{ fontSize: 16 }} to={`/${Routes.login}`}>
            {t("Back to log in")}
          </Link>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <TitleH1 text={t("Sign up")} />
      <Form
        name="register"
        onFinish={onFinish}
        style={{ width: "100%", marginTop: 24 }}
      >
        <FormItem
          label="Email"
          placeholder="Email"
          formItemProps={{
            name: "email",
            rules: [
              {
                min: 1,
                max: 240,
                type: "email",
                message: email,
              },
              { required: true, message: required },
            ],
          }}
        />
        <Row gutter={16}>
          <Col span={12} style={{ padding: 0 }}>
            <FormItem
              label={t("First name")}
              placeholder={t("First name")}
              formItemProps={{
                name: "first_name",
                rules: [{ required: true, message: required }],
              }}
            />
          </Col>
          <Col span={12} style={{ padding: 0 }}>
            <FormItem
              label={t("Last name")}
              placeholder={t("Last name")}
              formItemProps={{
                name: "last_name",
                rules: [{ required: true, message: required }],
              }}
            />
          </Col>
        </Row>

        <FormItem
          label={t("Password")}
          placeholder={t("Password")}
          formItemProps={{
            name: "password",
            rules: [{ required: true, message: required }],
          }}
          type="password"
        />
        <FormItem
          label={t("Repeat password")}
          placeholder={t("Repeat password")}
          formItemProps={{
            name: "password_confirmation",
            rules: [{ required: true, message: required }],
          }}
          type="password"
        />

        <Form.Item>
          <ButtonStyled type="primary" htmlType="submit" loading={loading}>
            {t("Create an account")}
          </ButtonStyled>
        </Form.Item>

        <ErrorText text={error ?? ""} />

        <Row style={{ marginBottom: 20 }} justify="center" gutter={24}>
          <Col style={{ cursor: "pointer" }}>
            <Link to={ENDPOINTS.socialNetworks(AuthType.GOOGLE?.toLowerCase())}>
              <Google />
            </Link>
          </Col>
          <Col style={{ cursor: "pointer" }}>
            <Link
              to={ENDPOINTS.socialNetworks(AuthType.LINKEDIN?.toLowerCase())}
            >
              <Linkedin />
            </Link>
          </Col>
          <Col style={{ cursor: "pointer" }}>
            <Link
              to={ENDPOINTS.socialNetworks(AuthType.FACEBOOK?.toLowerCase())}
            >
              <Facebook />
            </Link>
          </Col>
        </Row>

        <Row justify="space-between">
          <Text>{t("Already have an account?")}</Text>
          <Link
            style={{
              fontSize: 16,
              fontWeight: 700,
              color: Colors.MainBlue500,
            }}
            to={`/${Routes.login}`}
          >
            {t("Log in")}
          </Link>
        </Row>
      </Form>
    </Container>
  );
};
