import { Flex, Form, Modal, Typography, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  FormItem,
  FormNumber,
  TextAreaItem,
} from "shared/components/Form/FormItems";
import { validationErrorMessages } from "constants/form";
import {
  ButtonApprove,
  ButtonTransparent,
  openNotification,
} from "shared/components";
import { selectProject } from "store/projectSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { useAppSelector } from "store/hooks";
import { selectUser } from "store/userSlice";

const { Title } = Typography;
const { required, email } = validationErrorMessages;

export const ContactSales = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { isAuthorized } = useAppSelector(selectUser);
  const { project } = useSelector(selectProject);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = (e: any) => {
    e.preventDefault();
    form.resetFields();
    setOpen(false);
  };

  const onFinish = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        axios
          .post(
            isAuthorized
              ? ENDPOINTS.authContactForm()
              : ENDPOINTS.contactForm(),
            {
              ...values,
              project_id: project?.id,
              phone: String(project?.phone),
            }
          )
          .then(() => {
            setOpen(false);
            setLoading(false);
            openNotification("success");
          })
          .catch((e) => {
            openNotification("error");
          })
          .finally(() => setLoading(false));
      })
      .catch((info) => {
        console.log("Validation failed:", info);
      });
  }, [project, open]);

  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === "Enter") {
        onFinish();
      }
    };
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onFinish]);

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ fontWeight: 800 }}>
          Contact sales
        </Title>
      }
      onCancel={handleCancel}
      width={400}
      footer={null}
    >
      <Form
        name="contact_sales"
        onFinish={onFinish}
        style={{ marginTop: 16 }}
        form={form}
      >
        {!isAuthorized && (
          <>
            <Row gutter={16}>
              <Col span={12} style={{ padding: 0 }}>
                <FormItem
                  label="First name"
                  placeholder="First name"
                  formItemProps={{
                    name: "first_name",
                    rules: [{ required: true, message: required }],
                  }}
                />
              </Col>
              <Col span={12} style={{ padding: 0 }}>
                <FormItem
                  label="Last name"
                  placeholder="Last name"
                  formItemProps={{
                    name: "last_name",
                    rules: [{ required: true, message: required }],
                  }}
                />
              </Col>
            </Row>
            <FormItem
              label="Email"
              placeholder="Email"
              formItemProps={{
                name: "email",
                rules: [
                  {
                    min: 1,
                    max: 240,
                    type: "email",
                    message: email,
                  },
                  { required: true, message: required },
                ],
              }}
            />
          </>
        )}
        <FormNumber
          label={"Phone number"}
          placeholder={"Phone number"}
          formItemProps={{
            name: ["phone"],
            rules: [{ required: true, message: required }],
          }}
          controls={false}
        />
        <TextAreaItem
          label="Notes"
          placeholder="Notes"
          formItemProps={{
            name: "notes",
            rules: [{ required: true, message: required }],
            style: { marginBottom: 0 },
          }}
        />
        <Flex justify="space-between" gap={16}>
          <ButtonTransparent handle={handleCancel} width="100%" />
          <ButtonApprove text="Send" loading={loading} width="100%" />
        </Flex>
      </Form>
    </Modal>
  );
};
