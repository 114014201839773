import { Col, Flex, Form, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import {
  ButtonTransparent,
  CustomDeviceImage,
  FormItemSelect,
  FormNumber,
} from "shared/components";
import { positionConnect, transformArrayToObject } from "./SideBarModal";

const baseUrl = process.env.REACT_APP_API_URL_IMAGE;

const { Title } = Typography;

export const PreviewModal = ({
  open,
  setOpen,
  data,
}: {
  open: boolean;
  setOpen: (item: boolean) => void;
  data: any;
}) => {
  const [form] = Form.useForm();
  const [image, setFileList] = useState<string>(
    data?.image ? `${baseUrl}${data.image}` : ""
  );
  const [connections, setConnections] = useState([1]);
  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    setFileList("");
  };

  const connectionsDevice = Form.useWatch(["connections"], form) ?? 0;
  const paramsConnect = positionConnect(Object.values(connectionsDevice));

  const { description, connectors, name, width, height, id } = data || {};

  const initialValues = {
    name: name,
    connections: transformArrayToObject(connectors),
    description: description,
    width: width,
    height: height,
    id: id,
  };

  useEffect(() => {
    if (connectors?.length) {
      const ids = connectors.map((item: any) => item?.id);
      setConnections(ids);
    }
  }, []);

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ fontWeight: 800 }}>
          {name}
        </Title>
      }
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <Form style={{ marginTop: 80 }} form={form} initialValues={initialValues}>
        <Flex align="center" justify="space-between" vertical>
          <CustomDeviceImage
            image={image}
            paramsConnect={paramsConnect}
            form={form}
            width={width}
            height={height}
          />
          <Flex justify="end" style={{ width: "100%", marginTop: 25 }}>
            <ButtonTransparent handle={handleCancel} />
          </Flex>
          <Col style={{ display: "none" }}>
            {connections?.map((_: any, index: number) => (
              <>
                <FormItemSelect
                  formItemProps={{
                    name: ["connections", `connection_${index}`, "type"],
                  }}
                />
                <FormItemSelect
                  formItemProps={{
                    name: [
                      "connections",
                      `connection_${index}`,
                      "connection_type_id",
                    ],
                  }}
                />
                <FormItemSelect
                  formItemProps={{
                    name: ["connections", `connection_${index}`, "position"],
                  }}
                />
                <FormNumber
                  formItemProps={{
                    name: ["connections", `connection_${index}`, "top"],
                  }}
                />
                <FormNumber
                  formItemProps={{
                    name: ["connections", `connection_${index}`, "left"],
                  }}
                />
                <FormNumber
                  formItemProps={{
                    name: ["connections", `connection_${index}`, "id"],
                  }}
                />
                <FormNumber
                  formItemProps={{
                    name: "width",
                  }}
                />
                <FormNumber
                  formItemProps={{
                    name: "height",
                  }}
                />
              </>
            ))}
          </Col>
        </Flex>
      </Form>
    </Modal>
  );
};
