import { notification } from "antd";
import { Colors } from "constants/colors";
import { t } from "i18next";

type NotificationType = "success" | "info" | "warning" | "error";

export const openNotification = (
  type: NotificationType,
  message?: string,
  description?: string
) => {
  return notification[type]({
    message: (
      <span style={type === "success" ? textSuccess : textError}>
        {type === "success"
          ? message ?? t("Action completed")
          : message ?? t("Action failed")}
      </span>
    ),
    description: description,
    style: type === "success" ? customStyleSuccess : customStyleError,
    duration: 3,
    icon: <span />,
    closeIcon: (
      <CloseIcon
        style={{
          stroke: type === "success" ? Colors.MainGreen600 : Colors.MainRed900,
        }}
      />
    ),
  });
};

const customStyleSuccess: React.CSSProperties = {
  backgroundColor: Colors.MainGreen50,
  border: `1px solid ${Colors.MainGreen100}`,
  borderRadius: "8px",
  padding: "16px",
};

const customStyleError: React.CSSProperties = {
  backgroundColor: Colors.MainRed50,
  border: `1px solid ${Colors.MainRed300}`,
  borderRadius: "4px",
  padding: "16px",
};

const textSuccess: React.CSSProperties = {
  color: Colors.MainGreen600,
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "18px",
};

const textError: React.CSSProperties = {
  color: Colors.MainRed900,
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "18px",
};

const CloseIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M0.5 0.5L6 6M11.5 11.5L6 6M6 6L11.5 0.5M6 6L0.5 11.5"
      stroke="#2E7D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    />
  </svg>
);
