import { Computer, Laptop, Mobile, Tablet } from "shared/icons";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "shared/hooks/useWindowWidth ";
import { Flex, Row, Typography } from "antd";
import { ButtonApprove } from "shared/components";

const { Title, Paragraph } = Typography;

export const SmallScreen = () => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const handleChange = () => navigate("/");

  const isSmallDevice = windowWidth < 600;

  const getTitleSize = () => {
    if (windowWidth < 450) {
      return "24px";
    } else if (isSmallDevice) {
      return "30px";
    } else {
      return "40px";
    }
  };

  const styleIcon = {
    width: !isSmallDevice ? 104 : 80,
    height: !isSmallDevice ? 104 : 80,
  };
  const styleIconCheckbox = {
    width: !isSmallDevice ? 33 : 16,
    height: !isSmallDevice ? 32 : 16,
  };

  return (
    <Flex
      justify="center"
      align="middle"
      vertical
      style={{
        height: "100%",
        maxWidth: 500,
        padding: 16,
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Title
        style={{
          fontWeight: 800,
          fontSize: getTitleSize(),
          lineHeight: isSmallDevice ? "32px" : "48px",
          marginBottom: 8,
          marginTop: 100,
        }}
      >
        Looks like you’re using a small screen
      </Title>
      <Paragraph style={{ fontSize: isSmallDevice ? 12 : 16 }}>
        Elveflow works best on desktops, laptops. If you’re on a tablet or
        mobile, consider changing your device for a better experience.
      </Paragraph>
      <Row justify="space-between" style={{ marginTop: 28 }}>
        <Flex vertical align="center">
          <Computer style={styleIcon} />
          <Checkbox style={styleIconCheckbox} />
        </Flex>
        <Flex vertical align="center">
          <Laptop style={styleIcon} />
          <Checkbox style={styleIconCheckbox} />
        </Flex>
        <Flex vertical align="center">
          <Tablet style={styleIcon} />
          <CheckboxError style={styleIconCheckbox} />
        </Flex>
        <Flex vertical align="center">
          <Mobile style={styleIcon} />
          <CheckboxError style={styleIconCheckbox} />
        </Flex>
      </Row>
      <ButtonApprove
        text="Back to website"
        handle={handleChange}
        style={{ width: "100%", marginTop: 40 }}
      />
    </Flex>
  );
};

const Checkbox = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    {...rest}
  >
    <rect x="4.75" y="4" width="24" height="24" rx="12" fill="#4EA5FF" />
    <path
      d="M9.75 16.8462L13.938 21L23.75 12"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckboxError = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    {...rest}
  >
    <rect
      x="5.25"
      y="4.5"
      width="23"
      height="23"
      rx="11.5"
      fill="white"
      stroke="#A5A5B0"
      strokeLinejoin="round"
    />
    <path d="M10.75 16H22.75" stroke="#A5A5B0" strokeLinecap="round" />
  </svg>
);
