import * as React from "react";

export const ArrowDown = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      {...rest}
    >
      <path
        d="M1 0.5L6 5.5L11 0.5"
        stroke="#1D1D39"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
