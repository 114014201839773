export const Mobile = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="104"
      viewBox="0 0 80 80"
      fill="none"
      {...rest}
    >
      <rect
        x="28.5"
        y="62.5"
        width="35"
        height="23"
        rx="1.5"
        transform="rotate(-90 28.5 62.5)"
        stroke="#1D1D39"
      />
      <rect
        x="38"
        y="31"
        width="1"
        height="4"
        rx="0.5"
        transform="rotate(-90 38 31)"
        fill="#1D1D39"
      />
    </svg>
  );
};
