export const UploadIcon = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M4 6C4 5.05719 4 4.58579 4.29289 4.29289C4.58579 4 5.05719 4 6 4H18C18.9428 4 19.4142 4 19.7071 4.29289C20 4.58579 20 5.05719 20 6V18C20 18.9428 20 19.4142 19.7071 19.7071C19.4142 20 18.9428 20 18 20H6C5.05719 20 4.58579 20 4.29289 19.7071C4 19.4142 4 18.9428 4 18V6Z"
        stroke="#1D1D39"
        strokeLinejoin="round"
      />
      <path
        d="M20 15H16L15 17H9L8 15H4"
        stroke="#1D1D39"
        strokeLinejoin="round"
      />
      <path
        d="M10 11L12 13M12 13L14 11M12 13L12 7"
        stroke="#1D1D39"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
