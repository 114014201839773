import * as React from "react";

export const Linkedin = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="40" height="40" rx="20" fill="#2867B2" />
      <path
        d="M14.5 30H10.25V16.625H14.5V30ZM12.375 14.75C11 14.75 10 13.75 10 12.375C10 11 11.125 10 12.375 10C13.75 10 14.75 11 14.75 12.375C14.75 13.75 13.75 14.75 12.375 14.75ZM30 30H25.75V22.75C25.75 20.625 24.875 20 23.625 20C22.375 20 21.125 21 21.125 22.875V30H16.875V16.625H20.875V18.5C21.25 17.625 22.75 16.25 24.875 16.25C27.25 16.25 29.75 17.625 29.75 21.75V30H30Z"
        fill="white"
      />
    </svg>
  );
};
