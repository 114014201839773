import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUserInfo } from "shared/interfaces";
import type { RootState } from "./store";
import { ENDPOINTS } from "shared/fethers";
import { statusFetching } from "constants/types";
import { TRegister } from "shared/fethers/authClient/auth/interfaces";
import axios from "axios";

type TState = {
  userInfo: IUserInfo | null;
  status: statusFetching;
  isAuthorized: boolean;
  error: string | null;
  roles: string[];

  statusRegister: statusFetching;
  errorRegister: string | null;
};

const initialState: TState = {
  userInfo: null,
  status: "init",
  isAuthorized: false,
  roles: [],
  error: null,
  statusRegister: "init",
  errorRegister: null,
};

const getCsrfToken = async () => {
  try {
    const response = await axios.get(ENDPOINTS.sanctum());
    return response;
  } catch (error) {
    console.error("Error fetching CSRF token:", error);
  }
};

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

export const login = createAsyncThunk(
  "user/Login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    await getCsrfToken();
    try {
      const res = await axios.post(ENDPOINTS.token(), {
        email,
        password,
      });
      if (res && localStorage) {
        localStorage.setItem("access_token", res?.data.access_token);
      }
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const register = createAsyncThunk(
  "user/Register",
  async ({ values }: { values: TRegister }, { rejectWithValue }) => {
    await getCsrfToken();
    try {
      const res = axios.post(ENDPOINTS.register(), {
        ...values,
      });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "user/Info",
  async (_, { rejectWithValue }) => {
    await getCsrfToken();
    try {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
      }
      const userInfo = await axios.get(ENDPOINTS.user());
      if (userInfo?.data?.user) {
        localStorage.setItem("user", JSON.stringify(userInfo?.data?.user));
      }
      return userInfo;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/ForgotPassword",
  async (email: string, { rejectWithValue }) => {
    await getCsrfToken();
    try {
      const res = await axios.post(ENDPOINTS.forgotPassword(), {
        email,
      });
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/ForgotPassword",
  async (values: any, { rejectWithValue }) => {
    await getCsrfToken();
    try {
      const res = await axios.post(ENDPOINTS.resetPassword(), values);
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const logout = createAsyncThunk("user/Logout", async () => {
  //await getCsrfToken();
  localStorage.removeItem("access_token");
  await axios.post(ENDPOINTS.logoutUser());
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initSuccess: (state: TState, action) => {
      state.userInfo = action?.payload?.data;
      state.isAuthorized = true;
      state.status = "success";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state) => {
        state.userInfo = null;
        state.isAuthorized = false;
        state.status = "error";
        state.error = null;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.status = "fetching";
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.isAuthorized = true;
        state.status = "success";
        state.error = null;
        state.userInfo = payload?.data?.user;
        state.roles = payload?.data?.user?.roles;
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.userInfo = null;
        state.isAuthorized = false;
        state.status = "error";
        state.error = null;
      })

      .addCase(register.pending, (state) => {
        state.statusRegister = "fetching";
      })
      .addCase(register.fulfilled, (state) => {
        state.statusRegister = "success";
        state.errorRegister = null;
      })
      .addCase(register.rejected, (state, { error }) => {
        state.statusRegister = "error";
        state.errorRegister = error.message ?? "Something went wrong";
      })

      .addCase(login.fulfilled, (state) => {
        state.error = null;
      })
      .addCase(login.rejected, (state) => {
        state.error = "Invalid login or password";
      });
  },
});

export const { initSuccess } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export const selectUserRoles = (state: RootState) => {
  return ["state?.user?.roles"];
};

export default userSlice.reducer;
