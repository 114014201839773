import { Flex, Modal, Typography } from "antd";
import { useAppDispatch } from "store/hooks";
import { Project, TypeDropDown } from "shared/interfaces";
import { Routes } from "constants/routes";
import { useNavigate } from "react-router-dom";
import {
  getTypeRequest,
  ButtonApprove,
  ButtonTransparent,
  openNotification,
} from "shared/components";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { t } from "i18next";
import { useCallback, useEffect } from "react";

const { Title, Text } = Typography;

export const DeleteProject = ({
  open,
  setOpen,
  data,
  type,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: Project;
  type: TypeDropDown;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isVIEW = type === TypeDropDown.VIEW;
  const isEDIT = type === TypeDropDown.EDIT;
  const { id, state_template } = data || {};

  const onFinish = useCallback(async () => {
    await axios
      .delete(ENDPOINTS.projectById(id))
      .then(() => {
        if (isVIEW || isEDIT) {
          navigate(`/${Routes.base}`);
        } else {
          getTypeRequest(dispatch, state_template);
        }
        openNotification("success");
      })
      .catch(() => openNotification("error"))
      .finally(() => handleClickModal());
  }, [open, id, data]);

  const handleClickModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === "Enter") {
        onFinish();
      }
    };
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onFinish]);

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ marginBottom: 8, fontWeight: 800 }}>
          Delete {data?.name}
        </Title>
      }
      onCancel={handleClickModal}
      width={422}
      footer={null}
    >
      <Text style={{ fontSize: 16, lineHeight: 1.5 }}>
        {t("Are you sure you want to delete project?")}
      </Text>
      <Flex justify="space-between" gap={16}>
        <ButtonTransparent handle={handleClickModal} width="100%" />
        <ButtonApprove
          text={t("Yes, delete it")}
          handle={onFinish}
          width="100%"
        />
      </Flex>
    </Modal>
  );
};
