import {
  InputProps as AntInputProps,
  Col,
  ColProps,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";
import { FormItemProps as _FormItemProps } from "antd/lib/form";
import { Colors } from "constants/colors";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
const { Text } = Typography;
const { TextArea } = Input;

export interface FormItemProps {
  name?: string;
  label?: ReactNode;
  placeholder?: string;
  options?: any;
  disabled?: boolean;
  allowClear?: boolean;
  formItemProps?: _FormItemProps;
  colProps?: ColProps;
  highlightField?: boolean;
}

type InputProps = AntInputProps;
interface BaseFormItemProps extends FormItemProps {
  inputProps?: InputProps;
  noPaddingRight?: boolean;
  isTopLabel?: boolean;
  type?: "password" | "text";
  controls?: boolean
}

export const FormItem: React.FC<BaseFormItemProps> = ({
  placeholder,
  disabled,
  formItemProps = {},
  inputProps = {},
  colProps = {},
  label,
  type = "text",
}) => {
  return (
    <Col
      span={24}
      {...colProps}
      style={{
        ...(colProps?.style || {}),
      }}
    >
      {label && (
        <Col style={{ marginBottom: 8, textAlign: "left", padding: 0 }}>
          <Text color={Colors.MainBlue100}>{label}</Text>
        </Col>
      )}
      <FormItemStyled {...formItemProps}>
        {type === "password" ? (
          <InputStyledPassword
            placeholder={placeholder}
            disabled={disabled}
            {...inputProps}
          />
        ) : (
          <InputStyled
            placeholder={placeholder}
            disabled={disabled}
            {...inputProps}
          />
        )}
      </FormItemStyled>
    </Col>
  );
};

export const FormItemSelect: React.FC<any> = ({
  placeholder,
  disabled,
  formItemProps = {},
  inputProps = {},
  colProps = {},
  label,
  options,
  type = "text",
  onChange,
}) => {
  return (
    <Col
      span={24}
      {...colProps}
      style={{
        ...(colProps?.style || {}),
      }}
    >
      <Col style={{ marginBottom: 8, textAlign: "left", padding: 0 }}>
        <Text color={Colors.MainBlue100}>{label}</Text>
      </Col>
      <FormItemStyled {...formItemProps}>
        <SelectStyled
          placeholder={placeholder}
          disabled={disabled}
          options={options}
          onChange={onChange}
        />
      </FormItemStyled>
    </Col>
  );
};

export const TextAreaItem: React.FC<BaseFormItemProps> = ({
  placeholder,
  disabled,
  formItemProps = {},
  colProps = {},
  label,
  type = "text",
}) => {
  return (
    <Col
      span={24}
      {...colProps}
      style={{
        ...(colProps?.style || {}),
      }}
    >
      {label && (
        <Col style={{ marginBottom: 8, textAlign: "left", padding: 0 }}>
          <Text color={Colors.MainBlue100}>{label}</Text>
        </Col>
      )}
      <FormItemStyled {...formItemProps}>
        <TextAreaStyled
          placeholder={placeholder}
          disabled={disabled}
          rows={4}
        />
      </FormItemStyled>
    </Col>
  );
};

export const FormNumber: React.FC<BaseFormItemProps> = ({
  placeholder,
  disabled,
  formItemProps = {},
  colProps = {},
  label,
  controls = true,
}) => {
  return (
    <Col
      span={24}
      {...colProps}
      style={{
        ...(colProps?.style || {}),
      }}
    >
      {label && (
        <Col style={{ marginBottom: 8, textAlign: "left", padding: 0 }}>
          <Text color={Colors.MainBlue100}>{label}</Text>
        </Col>
      )}
      <FormItemStyled {...formItemProps}>
        <NumberStyled
          placeholder={placeholder}
          disabled={disabled}
          controls={controls}
        />
      </FormItemStyled>
    </Col>
  );
};

export const InputCss = css`
  min-height: 40px !important;
  font-size: 16px;
  color: #1d1d39;
  border-radius: 4px;
  border: 2px solid ${Colors.MainBlue100} !important;

  &:hover,
  &:focus {
    border-color: ${Colors.gray1} !important;
    box-shadow: none !important;
  }
`;

export const InputStyled = styled(Input)`
  ${InputCss}
  padding-left: 16px !important;
  &:focus {
    border-color: ${Colors.gray1} !important;
    box-shadow: none !important;
  }

  &:disabled {
    border-color: ${Colors.MainDark100} !important;
    background-color: ${Colors.MainDark50} !important;
    color: ${Colors.MainDark200} !important;
    &:hover {
      border-color: ${Colors.MainDark100} !important;
    }
  }
`;

export const NumberStyled = styled(InputNumber)`
  ${InputCss}
  padding: 0px !important;
  &:focus {
    border-color: ${Colors.gray1} !important;
    box-shadow: none !important;
  }

  &:disabled {
    border-color: ${Colors.MainDark100} !important;
    background-color: ${Colors.MainDark50} !important;
    color: ${Colors.MainDark200} !important;
    &:hover {
      border-color: ${Colors.MainDark100} !important;
    }
  }
`;

export const TextAreaStyled = styled(TextArea)`
  ${InputCss}
  padding-left: 16px !important;
  &:focus {
    border-color: ${Colors.gray1} !important;
    box-shadow: none !important;
  }

  &:disabled {
    border-color: ${Colors.MainDark100} !important;
    background-color: ${Colors.MainDark50} !important;
    color: ${Colors.MainDark200} !important;
    &:hover {
      border-color: ${Colors.MainDark100} !important;
    }
  }
`;

export const InputStyledPassword = styled(Input.Password)`
  ${InputCss}
  padding-left: 16px !important;
  &:focus {
    border-color: ${Colors.gray1} !important;
    box-shadow: none !important;
  }

  &:disabled {
    border-color: transparent !important;
    background-color: ${Colors.gray2} !important;
    &:hover {
      border-color: ${Colors.gray1} !important;
    }
  }
`;

export const FormItemStyled: React.FC<FormItemProps> = styled(Form.Item)`
  margin-bottom: 16px;
  text-align: left;
`;

export const SelectStyled = styled(Select)`
  ${InputCss}
  &:focus {
    border-color: ${Colors.gray1} !important;
    box-shadow: none !important;
  }

  &:disabled {
    border-color: ${Colors.MainDark100} !important;
    background-color: ${Colors.MainDark50} !important;
    color: ${Colors.MainDark200} !important;
    &:hover {
      border-color: ${Colors.MainDark100} !important;
    }
  }
`;
