import { Dropdown, DropdownProps, MenuProps, Space, Typography } from "antd";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { logout, selectUser } from "store/userSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useNavigate } from "react-router-dom";
import { Routes as Link } from "constants/routes";
import { ArrowDown } from "shared/icons";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const UserNme: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { userInfo, isAuthorized } = useAppSelector(selectUser);
  const roles = userInfo?.roles[0];
  const { t } = useTranslation();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "profile") {
      setOpen(false);
      navigate(`/${Link.myProfilePage}`);
    }
    if (e.key === "logout") {
      setOpen(false);
      dispatch(logout()).finally(() => window.location.reload());
      navigate(`/${Link.base}`);
    }
  };

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const itemsAuth: MenuProps["items"] = [
    {
      label: t("My profile"),
      key: "profile",
    },
    {
      label: t("Log out"),
      key: "logout",
    },
  ];
  const items: MenuProps["items"] = [
    {
      label: "info",
      key: "info",
    },
  ];

  const rolesName = t(roles ?? '') + ":";
  return (
    <Dropdown
      menu={{
        items: isAuthorized ? itemsAuth : items,
        onClick: handleMenuClick,
      }}
      onOpenChange={handleOpenChange}
      open={open}
    >
      <button
        onClick={(e) => e.preventDefault()}
        style={{
          border: "none",
          background: "none",
          color: "#1D1D39",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        <Space align="center">
          <Text style={{ fontWeight: 600 }}>
            {roles ? rolesName : ""} {userInfo?.name ?? "User name"}
          </Text>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowDown />
          </div>
        </Space>
      </button>
    </Dropdown>
  );
};
