export const Tablet = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="104"
      viewBox="0 0 105 104"
      fill="none"
      {...rest}
    >
      <rect
        x="13.5"
        y="23.5"
        width="79"
        height="57"
        rx="3.5"
        stroke="#1D1D39"
      />
      <rect
        x="19.5"
        y="26.5"
        width="70"
        height="51"
        rx="2.5"
        fill="white"
        stroke="#1D1D39"
      />
      <rect x="16" y="47" width="1" height="10" rx="0.5" fill="#1D1D39" />
    </svg>
  );
};
