import { Modal, Typography, Form, FormProps, Row, Col, Flex } from "antd";
import { validationErrorMessages } from "constants/form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonApprove, ButtonTransparent } from "shared/components";
import { FormItem } from "shared/components/Form/FormItems";
import { useAppDispatch } from "store/hooks";
import { addUserManager, getData } from "store/manageUserSlice";

const { Title } = Typography;
const { required, email } = validationErrorMessages;

type FieldType = {
  email: string;
  first_name: string;
  last_name: string;
};

export const CreateUser = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    setLoading(true);
    dispatch(addUserManager({ values }))
      .then(() => {
        dispatch(getData());
        handleCancel();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ fontWeight: 800 }}>
          {t("Create user")}
        </Title>
      }
      onCancel={handleCancel}
      width={400}
      footer={null}
    >
      <Form name="add_user" onFinish={onFinish} style={{ marginTop: 16 }}>
        <FormItem
          label="Email"
          placeholder="Email"
          formItemProps={{
            name: "email",
            rules: [
              {
                min: 1,
                max: 240,
                type: "email",
                message: email,
              },
              { required: true, message: required },
            ],
          }}
        />
        <Row gutter={16}>
          <Col span={12} style={{ padding: 0 }}>
            <FormItem
              label={t("First name")}
              placeholder={t("First name")}
              formItemProps={{
                name: "first_name",
                rules: [
                  { required: true, message: required },
                  { min: 2, message: t("Must be at least 2 characters") },
                  { max: 30, message: t("Cannot be more than 30 characters") },
                ],
              }}
            />
          </Col>
          <Col span={12} style={{ padding: 0 }}>
            <FormItem
              label={t("Last name")}
              placeholder={t("Last name")}
              formItemProps={{
                name: "last_name",
                rules: [
                  { required: true, message: required },
                  { min: 2, message: t("Must be at least 2 characters") },
                  { max: 30, message: t("Cannot be more than 30 characters") },
                ],
              }}
            />
          </Col>
        </Row>
        <FormItem
          label={t("Password")}
          placeholder={t("Password")}
          formItemProps={{
            name: "password",
            rules: [
              { required: true, message: required },
              { min: 5, message: t("Must be at least 5 characters") },
            ],
            style: { marginBottom: 0 },
          }}
          type="password"
        />
        <Flex justify="space-between" gap={16}>
          <ButtonTransparent handle={handleCancel} width="100%" />
          <ButtonApprove loading={loading} width="100%" />
        </Flex>
      </Form>
    </Modal>
  );
};
