import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  language: "en", // Default language
};

axios.interceptors.request.use(
  (config) => {
    // Retrieve the language setting from localStorage
    const language = localStorage.getItem("language") || "en"; // Default to 'en' if not set

    // Set the Accept-Language header
    config.headers["Accept-Language"] = language;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
