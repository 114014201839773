import { Col, Flex, Typography } from "antd";
import { EmptyData, SequenceContext } from "./SequenceModal";
import { DndProvider, useDrag, useDrop, XYCoord } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useCallback, useContext, useRef } from "react";
import update from "immutability-helper";
import { Colors } from "constants/colors";
import styled from "styled-components";
import { DeleteIcon, MoveIcon } from "../icons";
import { ICondition } from "./DevicesAndConditions";

const { Text, Title } = Typography;
export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

export const ListChoosed = (props: ICondition) => {
  const { activeCard, setActiveCard } = props;
  const { sequence, setSequence } = useContext(SequenceContext);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setSequence((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      })
    );
  }, []);

  const deleteCard = (id: number) => {
    setSequence((prevItems: any) =>
      prevItems.filter((item: any) => item.id !== id)
    );
  };

  const renderCard = useCallback(
    (card: { id: number; text: string }, index: number) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          data={card}
          moveCard={moveCard}
          deleteCard={deleteCard}
          setActiveCard={setActiveCard}
          activeCard={activeCard}
        />
      );
    },
    [activeCard]
  );

  return (
    <Col
      style={{
        height: "100%",
        borderLeft: "1px solid #D2D2D7",
        borderRight: "1px solid #D2D2D7",
        padding: "0 32px",
        margin: "0 32px",
      }}
    >
      <Title level={5} style={{ fontWeight: 700, marginBottom: 16 }}>
        Settings:
      </Title>
      {sequence?.length === 0 && <EmptyData />}
      {sequence && sequence?.length > 0 && (
        <DndProvider backend={HTML5Backend}>
          <div style={{ width: "100%" }}>
            {sequence?.map((card: any, i: number) => renderCard(card, i))}
          </div>
        </DndProvider>
      )}
    </Col>
  );
};

export interface CardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  deleteCard: (id: number) => void;
  setActiveCard: (id: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Card = ({
  id,
  data,
  index,
  moveCard,
  deleteCard,
  setActiveCard,
  activeCard,
}: any) => {
  const { text, type, label, outputLabel, typeCondition, timer } = data || {};

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: any }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <CardStyled
      ref={ref}
      style={{
        opacity,
        backgroundColor: activeCard === id ? Colors.MainBlue50 : "",
        borderBottom:
          activeCard === id
            ? `1px solid ${Colors.MainDark500}`
            : `1px solid ${Colors.MainDark100}`,
      }}
      data-handler-id={handlerId}
      align="center"
      onClick={() => setActiveCard(id)}
      justify="space-between"
    >
      <Flex gap={16} align="center">
        <MoveIcon />
        {index + 1}
        <BlockAdd
          style={{
            backgroundColor:
              type === "condition" ? Colors.MainBlue500 : Colors.MainPurple400,
          }}
        >
          {text}
        </BlockAdd>
        <Text>
          {text === "Wait"
            ? timer ?? ""
            : text === "Go to"
            ? ""
            : `${label} ${outputLabel || ""}`}
        </Text>
      </Flex>
      <DeleteIcon
        onClick={() => deleteCard(id)}
        style={{ cursor: "pointer" }}
      />
    </CardStyled>
  );
};

export const ItemTypes = {
  CARD: "card",
};

const CardStyled = styled(Flex)`
  padding: 16px 0;
  background-color: white;
  cursor: move;
  padding-right: 8px;
  transition: 0.4s linear;
  &:hover {
    background-color: ${Colors.MainBlue50};
  }
`;

const BlockAdd = styled(Flex)`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: ${Colors.MainBlue500};
  height: 34px;
  width: 93px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: ${Colors.MainWait500};
  border-radius: 8px;
`;
