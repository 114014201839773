import { statusFetching } from "constants/types";

export type TRequestParams = Record<string, TRequestValues>;
export type TRequestValues =
  | TRequestValuePrimitive
  | Array<TRequestValuePrimitive>;
export type TRequestValuePrimitive = string | number | undefined;
export interface ServerResponse<T> {
  content: T[];
  size: number;
  totalPages: number;
  totalElements: number;
}

interface IPagination {
  currentPage?: number;
  from?: number;
  to?: number;
  lastPage?: number;
  perPage?: number;
  total?: number;
}
interface IFilters {
  [name: string]: string;
}
interface ISorters {
  [name: string]: TSorter;
}
export interface NormalizedError {
  code?: number | string;
  status?: number;
  message: string;
}
export type TSorter = "none" | "asc" | "desc";

export interface IStore<T> {
  data: T[];
  fetchStatus: statusFetching;
  pagination?: IPagination;
  sorter: ISorters;
  filters: IFilters;
  fetchError: NormalizedError | null;
  type?: string;
}

export interface IStoreProject<T> {
  project: T | {};
  temporaryProject: T | {};
  fetchStatus: statusFetching;
  fetchError: NormalizedError | null;
  simulation: boolean | null;
  publish: number;
  saved: boolean;
  favorite: boolean;
}

export interface IUserInfo {
  created_at: string;
  email: string;
  email_verified_at: string | null;
  first_name: string;
  id: number;
  last_name: string;
  name: string;
  roles: string[];
  updated_at: string;
}

export enum AuthType {
  GOOGLE = "GOOGLE",
  LINKEDIN = "LINKEDIN",
  FACEBOOK = "FACEBOOK",
}

export enum CONNECTIONS {
  PNEUMATIC = "pneumatic",
  LIQUID = "liquid",
  SENSOR_DATA = "sensor_data",
  VALVE_DATA = "valve_data",
}

export enum CATEGORY_NAME {
  INSTRUMENT = "instrument",
  SENSOR = "sensor",
  MICROFLUIDIC_CHIP = "microfluidic_chip",
  ACCESSORY = "accessory",
  CUSTOM_DEVICES = "custom_devices",
}

//Project
interface Position {
  x: number;
  y: number;
}

export interface NodePosition {
  position: "top" | "bottom" | "left" | "right";
  top: string;
  left: string;
}

export interface ConnectionType {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Connector {
  id: number;
  device_id: number;
  connection_type_id: number;
  type: "target" | "source";
  number: number;
  position: string;
  conditions: string;
  created_at: string;
  updated_at: string;
  connection_type: ConnectionType;
  left: string;
  top: string;
  connection?: string;
}

interface NodeData {
  label: string;
}

interface NodeMeasured {
  width: number;
  height: number;
}

export interface INode {
  id: string;
  type: string;
  position: Position;
  data: NodeData;
  node: INodesNode;
  measured: NodeMeasured;
  selected?: boolean;
  dragging?: boolean;
  width?: number;
  height?: number;
}

export interface INodesNode {
  connectors: Connector[];
  image: string;
}

interface EdgeStyle {
  stroke: string;
  strokeWidth: number;
}

interface IEdge {
  source: string;
  sourceHandle: string;
  target: string;
  targetHandle: string;
  type: string;
  style: EdgeStyle;
  id: string;
}

export interface Template {
  nodes: any;
  edges: any;
}

export interface Project {
  id: number;
  user_id: number;
  publish: number;
  name: string;
  template: Template;
  image: string | null;
  gif: string | null;
  description: string;
  created_at: string;
  updated_at: string;
  hide: number;
  state_template: number;
  copy_link: string | null;
  export_count: number | null;
  blank: number;
  is_favorite: boolean;
  favorite_count: number;
}

//Devices
export interface Device {
  id: number;
  name: string;
  category_id: number;
  preview: string;
  image: string;
  description: string;
  connectors_data: string;
  product_link: string;
  created_at: string;
  updated_at: string;
  category: ConnectionType;
  connectors: Connector[];
  versions: [];
  width: string;
  height: string;
}

export interface DevicesSchema {
  devices: Device[];
  categories: ConnectionType[];
  connection_types: ConnectionType[];
}

export interface IFavourites {
  created_at: string;
  favorite: number;
  id: number;
  project_id: number;
  updated_at: string;
  user_id: number;
  project_name: string;
}

export enum TypeDropDown {
  EDIT = "EDIT",
  DASHBOARD = "DASHBOARD",
  VIEW = "VIEW",
}

export enum ROLES {
  SUPER_ADMIN = "SuperAdmin",
  ADMIN = "Admin",
  USER = "User",
}