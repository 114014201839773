
export const Google = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#D2D2D7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9997 20.2271C29.9997 19.518 29.9348 18.8362 29.8142 18.1816H20.2039V22.0498H25.6955C25.4589 23.2998 24.74 24.3589 23.6593 25.068V27.5771H26.9571C28.8866 25.8362 29.9997 23.2725 29.9997 20.2271Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2027 30.0001C22.9578 30.0001 25.2676 29.1047 26.9559 27.5774L23.6581 25.0683C22.7444 25.6683 21.5756 26.0229 20.2027 26.0229C17.545 26.0229 15.2955 24.2638 14.4931 21.9001H11.084V24.491C12.763 27.7592 16.2138 30.0001 20.2027 30.0001Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4944 21.8997C14.2903 21.2997 14.1744 20.6588 14.1744 19.9997C14.1744 19.3406 14.2903 18.6997 14.4944 18.0997V15.5088H11.0853C10.3942 16.8588 10 18.3861 10 19.9997C10 21.6133 10.3942 23.1406 11.0853 24.4906L14.4944 21.8997Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2027 13.9773C21.7008 13.9773 23.0459 14.4818 24.1034 15.4727L27.0301 12.6045C25.2629 10.9909 22.9531 10 20.2027 10C16.2138 10 12.763 12.2409 11.084 15.5091L14.4931 18.1C15.2955 15.7363 17.545 13.9773 20.2027 13.9773Z"
        fill="#EA4335"
      />
    </svg>
  );
};
