import {
  Button,
  Col,
  Flex,
  Form,
  GetProp,
  Input,
  Modal,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { useEffect, useState } from "react";
import {
  ButtonApprove,
  ButtonTransparent,
  CustomDeviceImage,
  ErrorText,
  FormItem,
  FormItemSelect,
  FormNumber,
  openNotification,
} from "shared/components";
import { validationErrorMessages } from "constants/form";
import { UploadIcon } from "shared/icons";
import { Colors } from "constants/colors";
import { AddPlus } from "shared/icons";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getData, selectAppliances } from "store/appliancesSlice";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { Connector } from "shared/interfaces";
import { transformArrayForSelect } from "./DevicesPanel";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getProject, selectProject, updateProject } from "store/projectSlice";

const baseUrl = process.env.REACT_APP_API_URL_IMAGE;

const { Title, Text } = Typography;
const { required } = validationErrorMessages;

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

interface IFormValues {
  name: string;
  connections: Connector[];
  description: string;
  width: string;
  height: string;
  id: string;
}

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const SideBarModal = ({
  open,
  setOpen,
  data,
}: {
  open: boolean;
  setOpen: (item: boolean) => void;
  data: any;
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [image, setFileList] = useState<string>(
    data?.image ? `${baseUrl}${data.image}` : ""
  );
  const { temporaryProject, saved } = useAppSelector(selectProject);
  const [connections, setConnections] = useState([1]);
  const [previewImage, setPreviewImage] = useState<File | null>(null);
  const { connection_types } = useAppSelector(selectAppliances);
  const { t } = useTranslation();

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    setPreviewImage(null);
    setFileList("");
  };

  const onFinish = async (values: IFormValues) => {
    const { name, connections, description, width, height, id } = values || {};

    const formData = new FormData();
    if (previewImage) {
      formData.append("image", previewImage as any);
    }
    formData.append("name", name);
    formData.append("description", description);
    formData.append("connectors", JSON.stringify(Object.values(connections)));
    formData.append("category_id", JSON.stringify(data?.category_id ?? 5));
    formData.append("width", width);
    formData.append("height", height);

    const handleRequest = async () => {
      try {
        await axios.post(
          id ? ENDPOINTS.updateDevice(id) : ENDPOINTS.devices(),
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setOpen(false);
        openNotification("success");
        dispatch(getData());
        form.resetFields();
        setPreviewImage(null);
        setFileList("");
      } catch (e: any) {
        setError(e?.response?.data?.error);
        openNotification("error");
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    setError(null);

    if (!saved && id) {
      await dispatch(updateProject(temporaryProject));
      await dispatch(getProject(id));
    }

    await handleRequest();
  };

  const handleChange = async (file: File) => {
    const originFileBase64 = await getBase64(file as FileType);
    setFileList(originFileBase64);
    setPreviewImage(file);
  };

  const addConnection = () => {
    setConnections((prev: any) => [...prev, prev++]);
  };

  const removeConnection = (index: number) => {
    setConnections((prev: any) =>
      prev.filter((_: any, i: number) => i !== index)
    );
  };

  const connectionsDevice = Form.useWatch(["connections"], form) ?? 0;
  const paramsConnect = positionConnect(Object.values(connectionsDevice));
  const getSpan = connections?.length > 1 ? 5 : 6;

  const [errorMessage, setErrorMessage] = useState("");

  const beforeUploadImage = (file: File) => {
    setFileList("");
    setPreviewImage(null);
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      setErrorMessage("You can only upload JPEG, JPG or PNG files!");
      return false;
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      setErrorMessage("Image must be smaller than 10MB!");
      return false;
    }
    setErrorMessage("");
    handleChange(file);
    return true;
  };

  const { description, connectors, name, width, height, id } = data || {};

  const initialValues = {
    name: name,
    connections: transformArrayToObject(connectors),
    description: description,
    width: width,
    height: height,
    id: id,
  };

  useEffect(() => {
    if (connectors?.length) {
      const ids = connectors.map((item: any) => item?.id);
      setConnections(ids);
    }
  }, []);

  return (
    <Modal
      open={open}
      title={
        <Title level={3} style={{ fontWeight: 800 }}>
          {t("Custom device creation")}
        </Title>
      }
      onCancel={handleCancel}
      footer={null}
      width={image ? 1000 : 650}
    >
      <Form
        name="device"
        onFinish={onFinish}
        style={{ marginTop: 16 }}
        form={form}
        initialValues={initialValues}
      >
        <Flex gap={24} align="center" justify="space-between">
          {image && (
            <CustomDeviceImage
              image={image}
              paramsConnect={paramsConnect}
              form={form}
              width={width}
              height={height}
            />
          )}
          <Col span={image ? 12 : 24}>
            <Col style={{ marginBottom: 8 }}>
              <Flex gap={8}>
                <Form.Item
                  label=""
                  rules={[{ required: !image, message: required }]}
                  style={{ margin: 0 }}
                  name={["image"]}
                >
                  <Flex gap={8}>
                    <Upload
                      listType="text"
                      style={{ display: "none" }}
                      maxCount={1}
                      multiple={false}
                      beforeUpload={beforeUploadImage}
                    >
                      <UploadIcon />
                    </Upload>
                    <Flex vertical>
                      <Text style={{ fontSize: 12, fontWeight: 700 }}>
                        {t("Upload file")}
                      </Text>
                      <Text style={{ fontSize: 12, color: Colors.MainDark200 }}>
                        {t("JPEG, JPG, PNG up to 10 MB")}
                      </Text>
                    </Flex>
                  </Flex>
                </Form.Item>
              </Flex>
              <Col style={{ color: "#ff4d4f" }}>{errorMessage}</Col>
            </Col>
            <FormItem
              label={t("Name")}
              placeholder={t("Device name")}
              formItemProps={{
                name: "name",
                rules: [{ required: true, message: required }],
              }}
            />
            <Title level={5} style={{ fontWeight: 700, marginBottom: 16 }}>
              {t("Connections")}:
            </Title>
            {connections?.map((_: any, index: number) => (
              <Row gutter={16} key={index}>
                <Col span={5} style={{ padding: 0 }}>
                  <FormItemSelect
                    label={t("Type")}
                    placeholder={t("Type")}
                    formItemProps={{
                      name: ["connections", `connection_${index}`, "type"],
                      rules: [{ required: true, message: required }],
                    }}
                    options={[
                      { value: "target", label: "Output" },
                      { value: "source", label: "Input" },
                    ]}
                  />
                </Col>
                <Col span={5} style={{ padding: 0 }}>
                  <FormItemSelect
                    label={t("Flow")}
                    placeholder={"Flow"}
                    formItemProps={{
                      name: [
                        "connections",
                        `connection_${index}`,
                        "connection_type_id",
                      ],
                      rules: [{ required: true, message: required }],
                    }}
                    options={transformArrayForSelect(connection_types)}
                  />
                </Col>
                <Col span={getSpan} style={{ padding: 0 }}>
                  <FormItemSelect
                    label="Direction"
                    placeholder="Direction"
                    formItemProps={{
                      name: ["connections", `connection_${index}`, "position"],
                      rules: [{ required: true, message: required }],
                    }}
                    options={[
                      { value: "left", label: t("Left") },
                      { value: "top", label: t("Top") },
                      { value: "right", label: t("Right") },
                      { value: "bottom", label: t("Bottom") },
                    ]}
                  />
                </Col>
                <Col span={4} style={{ padding: 0 }}>
                  <FormNumber
                    label={t("Top %")}
                    placeholder={t("Top")}
                    formItemProps={{
                      name: ["connections", `connection_${index}`, "top"],
                      rules: [{ required: true, message: required }],
                    }}
                  />
                </Col>
                <Col span={4} style={{ padding: 0 }}>
                  <FormNumber
                    label={t("Left %")}
                    placeholder={t("Left")}
                    formItemProps={{
                      name: ["connections", `connection_${index}`, "left"],
                      rules: [{ required: true, message: required }],
                    }}
                  />
                </Col>
                <Col span={4} style={{ padding: 0, display: "none" }}>
                  <FormNumber
                    formItemProps={{
                      name: ["connections", `connection_${index}`, "id"],
                    }}
                  />
                </Col>
                {connections?.length > 1 && (
                  <Flex style={{ marginTop: 15 }}>
                    <DeleteOutlined
                      style={{ fontSize: 18, color: Colors.MainDark500 }}
                      onClick={() => removeConnection(index)}
                    />
                  </Flex>
                )}
              </Row>
            ))}

            {connections?.length < 16 && (
              <Button
                type="link"
                icon={<AddPlus />}
                iconPosition="start"
                style={{
                  color: Colors.MainBlue500,
                  fontSize: 16,
                  fontWeight: 700,
                  padding: 0,
                  marginBottom: 12,
                }}
                onClick={addConnection}
              >
                {t("new connection")}
              </Button>
            )}

            <Row gutter={16}>
              <Col span={7} style={{ padding: 0 }}>
                <FormNumber
                  label={t("Image width px")}
                  placeholder={t("Width")}
                  formItemProps={{
                    name: "width",
                    rules: [{ required: true, message: required }],
                  }}
                />
              </Col>
              <Col span={7} style={{ padding: 0 }}>
                <FormNumber
                  label={t("Image height px")}
                  placeholder={t("Height")}
                  formItemProps={{
                    name: "height",
                    rules: [{ required: true, message: required }],
                  }}
                />
              </Col>
            </Row>
            <Col style={{ marginBottom: 8, textAlign: "left", padding: 0 }}>
              <Text color={Colors.MainBlue100}>Description</Text>
            </Col>
            <Form.Item
              name="description"
              rules={[{ required: true, message: required }]}
              style={{ marginBottom: 0 }}
            >
              <Input.TextArea
                placeholder={t("Description text")}
                style={{ border: "2px solid #DCEDFF", fontSize: 16 }}
              />
            </Form.Item>
            <Form.Item name="id" style={{ display: "none" }}>
              <Input.TextArea />
            </Form.Item>
            <ErrorText
              text={error ?? ""}
              style={{ marginTop: 16, marginBottom: 0 }}
            />
            <Flex justify="space-between">
              <ButtonTransparent
                handle={handleCancel}
                style={{ width: "47%" }}
              />
              <ButtonApprove
                text="Save"
                loading={loading}
                style={{ width: "47%" }}
              />
            </Flex>
          </Col>
        </Flex>
      </Form>
    </Modal>
  );
};

export const positionConnect = (arr: Connector[]) => {
  const result: any = [];
  arr.length > 0 &&
    arr.forEach((item: any) => {
      const { position, top: topPercent, left: leftPercent } = item || {};
      if (!isExist(topPercent) && !isExist(leftPercent) && !!position) {
        const top =
          position === "top"
            ? `${topPercent}%`
            : position === "bottom"
            ? `${topPercent}%`
            : `${topPercent}%`;
        const left =
          position === "left"
            ? `${leftPercent}%`
            : position === "right"
            ? `${leftPercent}%`
            : `${leftPercent}%`;

        result.push({
          ...item,
          top,
          left,
        });
      }
    });

  return result;
};

export const isExist = (value: any) => _.isNil(value) || value === "";

export const transformArrayToObject = (inputArray: any) => {
  return inputArray?.reduce((result: any, item: any, index: number) => {
    result[`connection_${index}`] = {
      type: item.type,
      connection_type_id: item.connection_type_id,
      position: item.position,
      top: parseInt(item.top, 10),
      left: parseInt(item.left, 10),
      id: item?.id,
    };
    return result;
  }, {});
};
