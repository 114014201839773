import { Button, Dropdown, Menu } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ArrowDown } from "shared/icons";
import { getData } from "store/appliancesSlice";
import { setLanguage } from "store/languageSlice";
import { AppDispatch } from "store/store";
import styled from "styled-components";

export const Languages: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const language = localStorage.getItem("language") || "en";

  const [selectedLanguage, setSelectedLanguage] = useState(
    language === "en" ? "English" : "French"
  );

  const handleMenuClick = async (e: any) => {
    const type = e.key === "English" ? "en" : "fr";
    setSelectedLanguage(e.key);
    i18n.changeLanguage(type);
    dispatch(setLanguage(e));
    dispatch(getData());
    localStorage.setItem("language", type);
  };

  const menuItems = [
    {
      label: t("English"),
      key: "English",
    },
    {
      label: t("French"),
      key: "French",
    },
  ];

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return (
    <DropdownStyled overlay={menu} trigger={["hover"]}>
      <Button type="text">
        {selectedLanguage.slice(0, 3)} <ArrowDown />
      </Button>
    </DropdownStyled>
  );
};

const DropdownStyled = styled(Dropdown)`
  background: transparent !important;
  border: none;
  box-shadow: none;
`;
